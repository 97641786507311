<template>
    <div class="container_infoSite">
        <div class="container_infoSite__menu">
            <div class="container_infoSite__menu--item" :style="infoCollapse.Key == 'Que_es' ? activeItem : ''" @click="cambiarInfo('Que_es')">
                <div class="container_infoSite__menu--title">
                    ¿Qué es el observatorio?
                </div>
                <div class="container_infoSite__menu--arrow">
                    <div class="container_infoSite__menu--arrow_desktop">
                        <img src="@/assets/img/arrow-white-right.svg" v-if="infoCollapse.Key == 'Que_es'">
                        <img src="@/assets/img/arrow-gray-right.svg" v-else>
                    </div>
                    <div class="container_infoSite__menu--arrow_movil">
                        <img src="@/assets/img/arrow-up.svg" v-if="infoCollapse.Key == 'Que_es'">
                        <img src="@/assets/img/arrow-down.svg" v-else>
                    </div>
                </div>
            </div>
            <div class="container_infoSite__menu--description_item" v-if="infoCollapse.Key == 'Que_es'">
                <div class="container_infoSite__description--title">
                    {{infoCollapse.Titulo}}
                </div>
                <div class="container_infoSite__description--text" v-html="infoCollapse.Descripcion">
                </div>
            </div>
            <div class="container_infoSite__menu--item" :style="infoCollapse.Key == 'Para_quien' ? activeItem : ''" @click="cambiarInfo('Para_quien')">
                <div class="container_infoSite__menu--title">
                    ¿Para quién?
                </div>
                <div class="container_infoSite__menu--arrow">
                    <div class="container_infoSite__menu--arrow_desktop">
                        <img src="@/assets/img/arrow-white-right.svg" v-if="infoCollapse.Key == 'Para_quien'">
                        <img src="@/assets/img/arrow-gray-right.svg" v-else>
                    </div>
                    <div class="container_infoSite__menu--arrow_movil">
                        <img src="@/assets/img/arrow-up.svg" v-if="infoCollapse.Key == 'Para_quien'">
                        <img src="@/assets/img/arrow-down.svg" v-else>
                    </div>
                </div>
            </div>
            <div class="container_infoSite__menu--description_item" v-if="infoCollapse.Key == 'Para_quien'">
                <div class="container_infoSite__description--title">
                    {{infoCollapse.Titulo}}
                </div>
                <div class="container_infoSite__description--text" v-html="infoCollapse.Descripcion">
                </div>
            </div>
            <div class="container_infoSite__menu--item" :style="infoCollapse.Key == 'Por_que' ? activeItem : ''" @click="cambiarInfo('Por_que')">
                <div class="container_infoSite__menu--title">
                    ¿Por qué?
                </div>
                <div class="container_infoSite__menu--arrow">
                    <div class="container_infoSite__menu--arrow_desktop">
                        <img src="@/assets/img/arrow-white-right.svg" v-if="infoCollapse.Key == 'Por_que'">
                        <img src="@/assets/img/arrow-gray-right.svg" v-else>
                    </div>
                    <div class="container_infoSite__menu--arrow_movil">
                        <img src="@/assets/img/arrow-up.svg" v-if="infoCollapse.Key == 'Por_que'">
                        <img src="@/assets/img/arrow-down.svg" v-else>
                    </div>
                </div>
            </div>
            <div class="container_infoSite__menu--description_item" v-if="infoCollapse.Key == 'Por_que'">
                <div class="container_infoSite__description--title">
                    {{infoCollapse.Titulo}}
                </div>
                <div class="container_infoSite__description--text" v-html="infoCollapse.Descripcion">
                </div>
            </div>
        </div>
        <div class="container_infoSite__description">
            <div class="container_infoSite__description--title">
                {{infoCollapse.Titulo}}
            </div>
            <div class="container_infoSite__description--text" v-html="infoCollapse.Descripcion">
                
            </div>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            obs:'',
            observatorio:this.getClient(),
            infoCollapse: {
                Key: '',
                Titulo: '',
                Descripcion: ''
            },
            activeItem:{
                'background-color': this.getClient().primary_color != null ? this.getClient().primary_color :'#48C5B6',
                'font-weight': 700,
                color: '#FFFFFF'
            },
            showPixelFusepong:false,
        }
    },
    created() {
        this.cambiarInfo('Que_es');
    },
    methods: {
        cambiarInfo(Seccion){
            switch (Seccion) {
                case 'Que_es':
                    this.infoCollapse = {
                        Key: Seccion,
                        Titulo: 'Qué es el Observatorio',
                        Descripcion: this.getClient().what
                    }
                    break;
                case 'Para_quien':
                    this.infoCollapse = {
                        Key: Seccion,
                        Titulo: '¿Para quién?',
                        Descripcion: this.getClient().how
                    }
                    break;
                case 'Por_que':
                    this.infoCollapse = {
                        Key: Seccion,
                        Titulo: '¿Por qué?',
                        Descripcion: this.getClient().why
                    }
                    break;
            }
        },
        getGoogleCont(){
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-KSX75XX');
        },
        getPixelFusepong(){
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1660104024184520'); 
            fbq('track', 'PageView');
        },
         defineRoute(){
            
            const host = window.location.host
            const parts = host.split('.')
            if( parts[0] !== 'WWW' || 'www' ){
                this.obs=parts[0]
            }else{
                this.obs=parts[1]
            }
            console.log('parametros de ruta',host,parts)
        }
    },
    beforeMount(){
        if(this.obs='fusepong'){
            this.getPixelFusepong()
            this.getGoogleCont()
        }       
    },
    
}
</script>