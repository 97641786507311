<template>
  <div class="container_tableau">
    <h2>{{type.name}}</h2>
    <div :id="type.name" ref="tableau"></div>
  </div>
</template>
<script>

export default {
  props:{
    type:{
      type:Object
    },
  },
  data() {
    return {     
      options: {
        hideTabs: true,
        width: "100%",
        height: "600px",
      }
    }
  },
  methods: {
    initViz() {
      let elementMap = document.getElementById(this.type.name)
      let viz = new tableau.Viz(elementMap, this.type.url, this.options);
    },
    // goToScreen(){
    //   this.updateGraph(this.type)
    //   this.showModal='true'
    //   //this.$router.push({name:'Screen',params:{type:'mapa'}})
    // }
  },
  created(){
    console.log('MAPAS::::::MUESTRA INGRESO',this.type)
  },
  mounted() {
    this.initViz()
  }
}
</script>