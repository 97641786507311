<template>

    <div>
        <div class="container_info_video" >
          <h1 class="container_info_video__title">
            ¿Quiénes nos consultan?
          </h1>
        </div>

        <div class="info_video">
           <div class="info_video_row" @click="showModal(1)">
            <div class="info_video__video" >
              <div class="info_video__video--cotainer_img ">
                <img src="@/assets/img/icono_play.png" class="info_video__video--cotainer_img--icon">
                <img src="@/assets/img/estudiantes.jpg" class="info_video__video--cotainer_img--img">
                  <button 
                    type="button" 
                    
                    style="background-color:#edbc2a;border: 1px solid #edbc2a;"
                    class="video_button_movil"
                    
                  >
                    <span>Estudiantes</span>
 
                  </button>
              </div> 
                </div>

                <div style="text-align:center">
                  <button 
                    type="button" 
                    
                    style="background-color:#edbc2a;border: 1px solid #edbc2a;"
                    class="video_button"
                    
                  >
                    <span>Estudiantes</span>
 
                  </button>
                </div>  
            </div>

            <div class="info_video_row">
            <div class="info_video__video" >
              <div class="info_video__video--cotainer_img ">
                <img src="@/assets/img/icono_play.png" class="info_video__video--cotainer_img--icon">
                <img src="@/assets/img/campesino.jpg" class="info_video__video--cotainer_img--img">
                <button 
                    type="button" 
                    
                    style="background-color:#0b6c37;border: 1px solid #0b6c37"
                    class="video_button_movil"
                    
                  >
                    <span>Campesinos</span>
 
                  </button>
              </div>  
            </div>
                 <div style="text-align:center">
                  <button 
                    type="button" 
                    
                    style="background-color:#0b6c37;border: 1px solid #0b6c37"
                    class="video_button"
                    
                  >
                    <span>Campesinos</span>
 
                  </button>
                </div> 
            </div>

            <div class="info_video_row"  @click="showModal(3)">
                <div class="info_video__video" >
                <div class="info_video__video--cotainer_img " style="line-height: 1;">
                  <img src="@/assets/img/icono_play.png" class="info_video__video--cotainer_img--icon">
                <img src="@/assets/img/funcionario.jpg" class="info_video__video--cotainer_img--img">
                  <button 
                    type="button" 
                    
                    style="background-color:#1796be;border: 1px solid #1796be;line-height: normal;"
                    class="video_button_movil"
                    
                  >
                    <!-- <span style="position: absolute; margin-top: -17%; margin-left: -26%;">Nuestros</span>
                    <span style="position: absolute; margin-top: -2%; margin-left: -37%;"> funcionarios</span> -->
                    <span style="">Nuestros funcionarios</span>
                    <!-- <span style=""> </span> -->
 
                  </button>
              </div>  

                </div>
                <div style="text-align:center">
                  <button 
                    type="button" 
                    
                    style="background-color:#1796be;border: 1px solid #1796be"
                    class="video_button"
                    
                  >
                    <span>Nuestros funcionarios</span>
 
                  </button>
                </div>        
            </div>
            
        </div>


        <b-modal id="bv-modal-video" v-model="show" hide-footer size="xl" title="" ok-only ok-variant="secondary" ok-title="Cerrar">
          
          <div class="d-block text-center">
        
              <div class="video_container" v-if="videoSelect == 'estudiante'">
                <!-- <vue-core-video-player  :src="videoSrc1"></vue-core-video-player> -->
                <iframe width="450" class="video_container__video" height="315" src="https://www.youtube.com/embed/DbY90GaN_m4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>

              <div class="video_container" v-if="videoSelect == 'funcionario'">
                <!-- <vue-core-video-player  :src="videoSrc3"></vue-core-video-player> -->
                <iframe width="450" class="video_container__video" height="315" src="https://www.youtube.com/embed/eHbUZcbEFs0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>              </div>

          </div>

        </b-modal>

    </div>

</template>
<script>
export default {
    name: 'InfoVideo',
    props: {
        bannerImg: {
            type: String,
            default: ''
        },
        heigth: {
            type: String,
            default: '50px'
        },

    },
    data(){
        return {
            // banner: bannerImg
            showBuilding:true,
            program:[],
            // videoSrc1: require("../../assets/video/Estudiante.mp4"),
            // videoSrc3: require("../../assets/video/Funcionario.mp4"),
            videoSelect: '',
            show: false
        }
    },
    methods:{
      showModal(data){
        if(data == 1){
          this.videoSelect = 'estudiante'
        }
        if(data == 3){
          this.videoSelect = 'funcionario'
        }
        this.show = true
        
      }
    }
}
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}
.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>