<template>
    <div class="container_footer">
        <!-- <div class="container_footer--title">
            {{getClient().footer_title}}
        </div> -->
        <div class="container_footer__info_footer">
            <div class="container_footer__info_footer--logo">
                <div class="container_footer__info_footer--logo_img" :style="{'background-image': `url(${observatorio.logo_townhall.url})`,  'background-size': 'contain', 'background-position': 'center', 'background-repeat':'no-repeat'}" >
                    <!-- <img :src="observatorio.logo_townhall.url" height="100%" style="border: 1px solid #000"> -->
                </div>
            </div>

            <div class="container_footer__info_footer--info">
                <div class="container_footer__info_footer--contact">
                    <div class="container_footer__info_footer--title_contact">
                        Contáctanos
                    </div>
                    <div class="container_footer__info_footer--infocontact">
                        <div class="container_footer__info_footer--item_contact">
                            <div class="container_footer__info_footer--icon">
                                <img src="@/assets/img/icono-web.svg" alt="" class="container_footer__info_footer--icon_img" v-if="screenWidth > '575.98'">
                                <img src="@/assets/img/icono-web.png" alt="" class="container_footer__info_footer--icon_img" v-if="screenWidth < '575.98'">
                            </div>
                            <div class="container_footer__info_footer--text">www.{{getClient().url || 'observatorio'}}.com</div>
                        </div>
                        <div class="container_footer__info_footer--item_contact">
                            <div class="container_footer__info_footer--icon">
                                <img src="@/assets/img/icono-mail.svg" alt="" class="container_footer__info_footer--icon_img" v-if="screenWidth > '575.98'">
                                <img src="@/assets/img/icono-mail.png" alt="" class="container_footer__info_footer--icon_img" v-if="screenWidth < '575.98'">
                            </div>
                            <div class="container_footer__info_footer--text">{{getClient().email || 'observatorio@observatorio.com'}}</div>
                        </div>
                        <div class="container_footer__info_footer--item_contact">
                            <div class="container_footer__info_footer--icon">
                                <img src="@/assets/img/icono-phone.svg" alt="" class="container_footer__info_footer--icon_img" v-if="screenWidth > '575.98'">
                                <img src="@/assets/img/icono-phone.png" alt="" class="container_footer__info_footer--icon_img" v-if="screenWidth < '575.98'">
                            </div>
                            <div class="container_footer__info_footer--text">{{getClient().phone || '5555555555'}}</div>
                        </div>
                    </div>
                </div>
                <div class="container_footer__info_footer--socialnetworks">
                    <div class="container_footer__info_footer--title">
                        Síguenos
                    </div>
                    <div class="container_footer__info_footer--items_social">
                        <a :href='"https://www.facebook.com/"+observatorio.facebook_url' target="_blank">
                            <img src="@/assets/img/icono-fb.svg" alt="" v-if="screenWidth > '575.98'">
                            <img src="@/assets/img/icono-fb.png" alt="" v-if="screenWidth < '575.98'" style="width: 8px;height: 15px;">
                            <p>
                                @{{observatorio.facebook_url}}
                            </p>
                        </a>
                        <a :href='"https://www.instagram.com/"+observatorio.instagram_url' target="_blank">
                            <img src="@/assets/img/icono-ig.svg" alt="" v-if="screenWidth > '575.98'">
                            <img src="@/assets/img/icono-ig.png" alt="" v-if="screenWidth < '575.98'">
                            <p>
                                @{{observatorio.instagram_url}}
                            </p>
                        </a>
                        <a :href='"https://www.twitter.com/"+observatorio.twitter_url' target="_blank">
                            <img src="@/assets/img/icono-tw.svg" alt="" v-if="screenWidth > '575.98'">
                            <img src="@/assets/img/icono-tw.png" alt="" v-if="screenWidth < '575.98'">
                            <p>
                                @{{observatorio.twitter_url}}
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            observatorio:this.getClient(),
            screenWidth: window.innerWidth
        }
    },

    methods:{
         onResize() {
      this.screenWidth = window.innerWidth
    }
    },

    beforeMount(){
        //Console.Log('el footer',this.getClient())
        //Console.Log('Footer ruta',this.$route.params)

    },

    mounted(){
          this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    },
      beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
}
</script>
<style scoped>
    .redes{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>
