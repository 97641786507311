
<template>
  <div>

    

      <div class="vg-body" >
        <!-- <b-overlay :show="loading" rounded="sm">
          
        </b-overlay> -->

      <div >
        <div v-for="item in countries" class="vg-container " :key="item.id" >

          <a class="vg-link" @click="doTranslation(item.code)" :title="item.title"
            href="#">
                      <span class="vg-items">
                          <img :alt="item.alt"
                              :src="`https://cdn.jsdelivr.net/npm/vue-gtranslate/img/flags/__${item.title}.png`"
                              class="flag"/>

                        <span class="vg-text">{{ item.label }}</span>
                      </span>
          </a>

        </div>
      </div>

      </div>
    

    <div id="google_translate_element2"></div>

  </div>




</template>

<script>
import "vue-gtranslate/translator.css"
export default {
  name: "TranslateComponent",
  data() {
    return {

      countries: [

        {
          code: "en",
          title: "English",
          label: "Inglés"
        },

        {
          code: "es",
          title: "Spanish",
          label: "Español"
        },
     
      ],
      loading: true,
      showCountry: false
    }

  },

  created(){
    let me = this
    setTimeout(function () { 
        me.loading = false 
        me.showCountry = true
      } , 5000)
  },

  computed: {
    currentRouteName() {
      if (this.$route.path === "/") {
        return `${this.$route.path}`;
      } else {
        return `${this.$route.path}/`;
      }
    }
  },




  methods: {



  
    doTranslation(code) {


      console.log("lang", code)
      localStorage.setItem("lang", code);

      // const lang = localStorage.getItem("lang");

      // console.log('translate', code)

      this.$router
          .replace(`${this.currentRouteName}#googtrans(${code})`)

          .catch(err => {
            return err;
          });

      // this.$router.replace();
      window.location.reload();

      setTimeout(() => {
        // window.location.reload();
      }, 500);

      return false;
    }


  }
}
</script>

<style scoped>


a:link {
  text-decoration: none;
  font-size: large;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

.flag{
  width: 50px;
}
</style>
