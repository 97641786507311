<template>
  <router-view></router-view> 
</template>
<script>
export default {
    data() {
        return {
            obs:'',
        }
    },
    methods:{
        getTownhall(){

            //Console.Log('Información OBS', this.obs)
            this.$http.get('townhalls/'+this.obs+'/url').then(response => {
                //Console.Log('INFORMACIÓN ALCALDÍA', response);
                this.loadDimension = false;
                this.updateClient(response.body);

            }).catch(error => {
                //Console.Log('HA OCURRIDO UN ERROR AL CONSULTAR LA ALCALDÍA', error)
            })

        },
        defineRoute(){
            
            const host = window.location.host
            const parts = host.split('.')
            if( parts[0] === 'WWW' || parts[0] === 'www'){
                this.obs=parts[1]
            }else{
                this.obs=parts[0]
            }
            
            console.log('parametros de ruta',host,parts)
            this.getTownhall()
        }
    },
    computed:{
     
    },
    created() {
        var lang = localStorage.getItem('lang')
        if(lang == null || lang == ''){
          localStorage.setItem("lang", 'es');
        }else{
            console.log('holiss')
        //      this.$router
        //   .replace(`${this.$router.currentRoute}#googtrans(${lang})`)
        }

        console.log('currentRouteName', this.$router.currentRoute)

        this.defineRoute()
        
        
    },
}
</script>
<style lang="scss" src="./assets/stylesheets/application.scss"></style>
