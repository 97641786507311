<template>
    <div>
        <div class="social_icons">
            <ul>
                <a :href='"https://www.facebook.com/"+observatorio.facebook_url' target="_blank">
                    <li class=facebook>
                        <img src="@/assets/img/icono-fb2.png" style="width: 12px;">
                    </li>
                </a>
                <a :href='"https://www.instagram.com/"+observatorio.instagram_url' target="_blank">
                    <li class=instagram style="">
                        <img src="@/assets/img/icono-ig2.png" style="width: 28px;">
                    </li>
                </a>
                <a :href='"https://www.twitter.com/"+observatorio.twitter_url' target="_blank">
                    <li class=twitter style="padding-top: 8px;">
                        <img src="@/assets/img/icono-tw2.png" style="width: 28px;">
                    </li>
                </a>
            </ul>
        </div>
    </div>        
</template>
<script>
export default {

    name: 'AboutIndicator',

    data(){
        return {
            observatorio:this.getClient(),
        }
    },
    methods:{
    
    }

};
</script>

<style lang="scss" scoped>

</style>