import Vue from 'vue'
import App from './App.vue'
import router from './vue/router'
import store from './vue/store/index'
import './vue/resource'
import { BootstrapVue } from 'bootstrap-vue';
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import 'vue-tableau'
import VuePersist from 'vue-persist'
import './vue/mixins'
import VueSvgGauge from 'vue-svg-gauge'
import VueSmoothScroll from 'vue2-smooth-scroll'
import VueCarousel from 'vue-carousel';
import VueCoreVideoPlayer from 'vue-core-video-player'
import VueGlide from 'vue-glide-js'
import 'vue-glide-js/dist/vue-glide.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import imageZoom from 'vue-image-zoomer';

// import vGoogleTranslate from "v-google-translate";
// Vue.use(vGoogleTranslate);

const ImageZoom = require('vue-image-zoomer').default;

Vue.component('image-zoom', ImageZoom);

Vue.component('v-select', vSelect)

Vue.use(VueGlide)

Vue.use(VueCoreVideoPlayer)

Vue.use(VueCarousel);

Vue.use(VueSmoothScroll, {
  duration: 2000,
  updateHistory: false,
  offset: -50
})

Vue.use(VueSvgGauge)

Vue.use(VuePersist)
Vue.use(BootstrapVue)


Vue.use(Chartkick.use(Chart))
// Chart.defaults.scale.gridLines.drawOnChartArea = false;
// Chart.defaults.scale.gridLines.display = false;

Vue.config.productionTip = false

Vue.component('Nav-bar', require('./components/shared/navbar.vue').default)
Vue.component('Banner', require('./components/shared/banner.vue').default)
Vue.component('Banner-Dynamic', require('./components/shared/bannerDynamic.vue').default)
Vue.component('Graphs', require('./components/shared/Graphs.vue').default)
Vue.component('Maps', require('./components/shared/Maps.vue').default)
Vue.component('Container', require('./components/shared/Container.vue').default)
Vue.component('Footer', require('./components/shared/footer.vue').default)
Vue.component('Container-Inicio', require('@/components/views/Inicio.vue').default)
Vue.component('Container-Dimensiones', require('@/components/views/Dimensions.vue').default)
Vue.component('Home', require('./components/Home.vue').default)
Vue.component('Modal-Map', require('./components/shared/Modal_map.vue').default)
Vue.component('dimension_special', require('./components/shared/DimensionSpecial.vue').default)
Vue.component('dimension_specialmobil', require('./components/shared/DimensionSpecialMobil.vue').default)
Vue.component('Product-Modal', require('./components/shared/ProductModal.vue').default)
Vue.component('Graph-Table-Modal', require('./components/shared/GraphTableModal.vue').default)
Vue.component('Graph-Table-Modal-Category', require('./components/shared/GraphTableModalCategory.vue').default)
Vue.component('Main-Dimension', require('./components/shared/MainDimension.vue').default)
Vue.component('Info-Banner', require('./components/views/InfoBanner.vue').default)
Vue.component('DimensionMovileList', require('./components/shared/DimensionsMovileList.vue').default)
Vue.component('RelevantNews', require('./components/shared/RelevantNews.vue').default)
Vue.component('Info-Video', require('./components/views/InfoVideo.vue').default)
Vue.component('About-Indicator', require('./components/views/AboutIndicator.vue').default)
Vue.component('About-Dimension', require('./components/views/AboutDimension.vue').default)
Vue.component('Menu-Movil', require('./components/shared/MenuMovil.vue').default)
Vue.component('Social', require('./components/shared/Social.vue').default)
Vue.component('About-Question', require('./components/views/AboutQuestion.vue').default)
Vue.component('Translate', require('./components/shared/Translate.vue').default)

//Icons
Vue.component('ArrowDownIcon', require('./components/shared/icons/ArrowDown.vue').default)
Vue.component('ArrowUpIcon', require('./components/shared/icons/ArrowUp.vue').default)
Vue.component('ReviewIcon', require('./components/shared/icons/Review.vue').default)
Vue.component('GeographyIcon', require('./components/shared/icons/Geography.vue').default)
Vue.component('EcologyIcon', require('./components/shared/icons/Ecology.vue').default)
Vue.component('EconomyIcon', require('./components/shared/icons/Economy.vue').default)
Vue.component('ShieldIcon', require('./components/shared/icons/Shield.vue').default)
Vue.component('HistoryIcon', require('./components/shared/icons/History.vue').default)
Vue.component('FlagIcon', require('./components/shared/icons/Flag.vue').default)
Vue.component('MenuIcon', require('./components/shared/icons/Menu.vue').default)


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
