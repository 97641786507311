<template>
  <div class="container_products">
    <div class="button__next--step" @click="otherProduct('left')">
      <div v-if="leftShow==true">
        <img
          :src="
            require('../../assets/img/icons-modal-products/icono-anterior.svg')
          "
          alt=""
        />
        <p>ATRAS</p>
      </div>
    </div>
    <div class="container_products--container__align">
      <div class="container_products--container__align">
        <div class="container_products--container">
          <div class="container_products--container__title">
            <p>{{ product.name }}</p>
          </div>
          <div class="container_products--container__metric">
            <div v-for="info in product.product_tables" :key="info" class="container_metric">
              <Graphs
                :type="{
                  data: info.data,
                  description: info.description,
                  name: info.name,
                  type: info.graph_type_id,
                }"
              />
              <p v-if="info.description" v-html="info.description" class="container_metric--description"></p>
            </div>
          </div>
          <div
            class="container_products--container__boton"
            @click="getOutModal"
          >
            <img
              :src="
                require('../../assets/img/icons-modal-products/icono-cerrar.svg')
              "
              alt=""
            />
            <p>CERRAR</p>
          </div>
        </div>
      </div>
    </div>
    <div class="button__next--step" @click="otherProduct('right')">
      <div v-if="rightShow==true">
        <img
          :src="
            require('../../assets/img/icons-modal-products/icono-siguiente.svg')
          "
          alt=""
        />
        <p>SIGUIENTE</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    product: {
      type: Object,
    },
    modalCategory: {
      type: Object,
    },
  },
  data() {
    return {
      findId:0,
      leftShow:false,
      rightShow:true
    };
  },
  methods: {
    getOutModal() {
      this.$emit("cancelProduct", false);
      //console.log("Respuesta ::: ", this.showModal);
    },
    searchIndex(value){
      if (this.modalCategory[this.findId].id==value){
        return this.findId
      }else{
        this.findId=this.findId+1
        this.searchIndex(value)
      }
      this.showSignals()
    },
    otherProduct(value){
      if (value=="right"){
        if(this.findId<this.modalCategory.length-1){
          this.findId=this.findId+1
          //console.log("probando el other",this.findId)
          this.showSignals()
          this.$emit("otherProduct", this.modalCategory[this.findId],this.modalCategory);
        }
      }
      if(value=="left"){
        if(this.findId>0){
          this.findId=this.findId-1
          //console.log("probando el other",this.findId)
          this.showSignals()
          this.$emit("otherProduct", this.modalCategory[this.findId],this.modalCategory);
        }
        
      }
    },
    //this function is for showing arrow's left a  or arrow's right
    showSignals(){
      if (this.findId != 0){
        this.leftShow=true
      }else{
        this.leftShow=false
      }
      if (this.findId == this.modalCategory.length-1){
        this.rightShow=false
      }else{
        this.rightShow=true
      }
      const obj ={
        index:this.findId,
        derecha:this.rightShow,
        izquierda:this.leftShow,
        tamaño:this.modalCategory.length-1
      }
      //console.log('probando',obj)
    }

  },
  beforeMount() {
    //console.log(this.product,this.modalCategory);
    //console.log("los pinches id",this.modalCategory[0].id)
    //console.log("los pinches id",this.modalCategory[this.modalCategory.length-1].id)
    this.searchIndex(this.product.id)
    //console.log('aqui fue',this.findId)
  },
};
</script>

<style scoped lang="scss">
.container_products {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 12;
  background: #00000080;
  top: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.button__next--step {
  width: 9%;
  height: 9vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}
.button__next--step:hover{
  border-radius: 50%;
  background-color: rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.button__next--step p{
  width: auto;
  color: white;
  padding: 0;
  margin: 0;
}
.container_products--container__align {
  display: block;
  justify-content: center;
  align-items: center;
  overflow-y:scroll;
  height: 80vh;
}
.container_products--container__align::-webkit-scrollbar {
  display: none;
}
.container_products--container {
  height: auto;
  width: 880px;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

   @media (min-width: 576px) and (max-width: 767.98px) { 
    width: auto;
  }

  @media (max-width: 575.98px) { 
    width: auto;
  }
  
}
.container_products--container__boton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
}
.container_products--container__boton p {
  width: 100%;
  text-align: center;
  margin-top: 5px;
}
.container_products--container__title {
  height: 70px;
  width: 100%;
  background: #69c061;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 3px 6px #00000029;
}
.container_products--container__title p {
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  padding: 0 20px;
}
.container_products--container__metric {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  padding: 30px 30px 0px 30px;
}
.one__product {
  height: 450px;
}
.container_metric{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #F4F4F4;
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
}

.container_metric--description{
  text-align: left;
  font: normal normal normal 13px/21px Roboto;
  letter-spacing: 0.33px;
  color: #383838;
  margin-bottom: 0px;
}
</style>