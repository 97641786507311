import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'loadPage',
    component: () => import('@/components/views/loadPage.vue'),
  },
  {
    path: '/prueba',
    name: 'Prueba',
    component: () => import('@/components/pages/prueba.vue')
  },
  {
    path: '/',
    name: 'Inicio',
    component: () => import('@/components/views/Inicio.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/components/Home.vue')

      },
      {
        path: 'Dimension/:id',
        name: 'Dimension',
        component: () => import('@/components/pages/Dimension.vue')
      },
      {
        path: 'Desarrollo',
        name: 'Develop',
        component: () => import('@/components/pages/Develop.vue')
      },
      {
        path: 'Segments/:id',
        name: 'Segments',
        component: () => import('@/components/pages/Segments.vue')
      },
      {
        path: 'Category/:id',
        name: 'Category',
        component: () => import('@/components/pages/Category.vue')
      },
      {
        path: 'Product/:id',
        name: 'Product',
        component: () => import('@/components/pages/Product.vue')
      },
      {
        path: 'Graph',
        name: 'Graph',
        component: () => import('@/components/pages/Graph.vue')
      },
      {
        path: 'About',
        name: 'About',
        component: () => import('@/components/views/About.vue')
      },
      {
        path: 'AboutObservatorio',
        name: 'AboutObservatorio',
        component: () => import('@/components/views/AboutObservatorio.vue')
      },
      {
        path: 'Maps',
        name: 'Maps',
        component: () => import('@/components/views/Maps.vue')
      },
      {
        path: 'AboutSite',
        name: 'AboutSite',
        component: () => import('@/components/views/AboutSite.vue')
      },
      {
        path: 'Indicators',
        name: 'Indicators',
        component: () => import('@/components/views/Indicators.vue')
      },
      {
        path: 'Faqs',
        name: 'Faqs',
        component: () => import('@/components/views/Faqs.vue')
      },
      {
        path: 'Contact',
        name: 'Contact',
        component: () => import('@/components/views/Contact.vue')
      },
      {
        path: 'Map',
        name: 'Map',
        component: () => import('@/components/pages/Map.vue')
      },
      {
        path: 'SubCategoryProduct',
        name: 'SubCategoryProduct',
        component: () => import('@/components/pages/SubCategoryProduct.vue')
      },
      {
        path: 'Article/:title/:id',
        name: 'Article',
        component: () => import('@/components/pages/Article.vue')
      },
      { 
        path: "*", 
        component: () => import('@/components/Home.vue'),
        beforeEnter: (to, from, next) => {
          next('/');    
       },
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  linkExactActiveClass: "nav-item_mobile_Active",
})

export default router
