<template>
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="57" viewBox="0 0 50 50">
        <path :fill="color" class="a" d="M49.023,221.3h-.391v-28.32a.977.977,0,0,0-.977-.977H39.844a.977.977,0,0,0-.977.977V221.3H36.133V198.25a.977.977,0,0,0-.977-.977H27.344a.977.977,0,0,0-.977.977V221.3H23.633V203.9a.977.977,0,0,0-.977-.977H14.844a.977.977,0,0,0-.977.977V221.3H11.133v-9.961a.977.977,0,0,0-.977-.977H2.344a.977.977,0,0,0-.977.977V221.3H.977a.977.977,0,0,0,0,1.953H49.023a.977.977,0,0,0,0-1.953Zm-45.7,0v-8.984H9.18V221.3Zm12.5,0V204.88H21.68V221.3Zm12.5,0v-22.07H34.18V221.3Zm12.5,0V193.953H46.68V221.3Z" transform="translate(0 -173.25)" />
        <path :fill="color" class="a" d="M406.444,14.129a5.045,5.045,0,0,0,2.683.961v.73a.977.977,0,0,0,1.953,0v-.843a4.228,4.228,0,0,0,3.068-3.365,3.67,3.67,0,0,0-2.525-4.2c-.179-.063-.361-.129-.543-.2V3.72a3.219,3.219,0,0,1,.736.368.977.977,0,0,0,1.174-1.561,5.252,5.252,0,0,0-1.91-.834V.977a.977.977,0,0,0-1.953,0v.711c-.109.024-.219.051-.33.084a3.486,3.486,0,0,0-2.392,2.733,3.09,3.09,0,0,0,1.018,3.046,8,8,0,0,0,1.7.979v4.6a3.2,3.2,0,0,1-1.613-.636.977.977,0,0,0-1.069,1.635Zm5.778-2.842A2.272,2.272,0,0,1,411.08,12.9v-3.6a1.748,1.748,0,0,1,1.142,1.994Zm-3.594-5.274a1.181,1.181,0,0,1-.3-1.146,1.6,1.6,0,0,1,.8-1.131V6.345A4.33,4.33,0,0,1,408.628,6.014Z" transform="translate(-366.353)" />
        <path :fill="color" class="a" d="M34.18,65.68a.977.977,0,0,0,1.381,0L46.83,54.411l3.234,3.234a.977.977,0,0,0,1.381,0l10.7-10.7.8.8a.977.977,0,0,0,1.667-.691v-6.07A.977.977,0,0,0,63.637,40h-6.07a.977.977,0,0,0-.691,1.667l.807.807L50.755,49.4l-3.234-3.234a.977.977,0,0,0-1.381,0L31.094,61.213a.977.977,0,0,0,0,1.381ZM46.83,48.24l3.234,3.234a.977.977,0,0,0,1.381,0l8.309-8.31a.977.977,0,0,0,.136-1.211h2.77v2.774a.977.977,0,0,0-1.2.142l-10.7,10.7-3.234-3.234a.977.977,0,0,0-1.381,0L34.87,63.609l-1.7-1.7Z" transform="translate(-27.8 -36.094)" />
    </svg>
</template>
<script>
export default {
    props:{
        color: {
            default: '#3E73BE',
            type: String
        }
    }
}
</script>