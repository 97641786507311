<template>
<div class="container_nav">
    <div class="container_nav__desktop">
        <a >
            <div class="container_nav--logo" :style="{'background-image': `url(${observatorio.logo_img.url})`, 'background-size': 'contain', 'background-position': 'center', 'background-repeat':'no-repeat'}"></div>
        </a>
        <!-- <div class="container_nav__title">
            <span class="container_nav__title--company">
                Observatorio territorial
            </span>
            <span class="container_nav__title--sub_company">
                EL RETIRO
            </span>
        </div> -->
       
            <img src="@/assets/img/Texto_observatorio_header.png" style="width: 65%;margin-top: 2%;">

        <!-- <div class="container_social">
             <a :href='"https://www.facebook.com/"+observatorio.facebook_url' target="_blank">
                <div 
                    class="container_social__button" 
                    :class="$route.name == 'Home' ? 'active_item_desktop':''" 
                    :style="cssItem" @click="getRoute('Home')"
                    style="background: #D3312C; "
                >
                    <img src="@/assets/img/icono-fb2.png" style="width: 12px;">
                </div>
             </a>
             <a :href='"https://www.instagram.com/"+observatorio.instagram_url' target="_blank">
                <div 
                    class="container_social__button" 
                    :class="$route.name == 'Home' ? 'active_item_desktop':''" 
                    :style="cssItem" @click="getRoute('Home')"
                    style="background: #D3312C; "
                >
                    <img src="@/assets/img/icono-ig2.png" style="width: 28px;">
                </div>
             </a>
             <a :href='"https://www.twitter.com/"+observatorio.twitter_url' target="_blank">
                <div 
                    class="container_social__button" 
                    :class="$route.name == 'Home' ? 'active_item_desktop':''" 
                    :style="cssItem" @click="getRoute('Home')"
                    style="background: #D3312C; "
                >
                    <img src="@/assets/img/icono-tw2.png" style="width: 28px;">
                </div>
             </a>
        </div> -->

        
    </div>
    <div class="container_nav--menu">
        <div 
            class="container_nav__item_menu" 
            :class="$route.name == 'Home' ? 'active_item_desktop':''" 
            :style="cssItem" @click="getRoute('Home')"
            style="background: #D3312C; width: 50px;"
        >
            <img src="@/assets/img/icono-home.png" style="width: 50px;">
        </div>
        <div 
            class="container_nav__item_menu" 
            :class="$route.name == 'AboutObservatorio' ? 'active_item_desktop':''" 
            :style="cssItem" @click="getRoute('AboutObservatorio')"
            style="background: #1796BE"
        >
            ¿Qué es el Observatorio?
        </div>
        <!-- <div 
            class="container_nav__item_menu" 
            :style="cssItem" 
            :class="$route.name == 'About' ? 'active_item_desktop':''" 
            @click="getRoute('About')"
            style="background: #0B6C37"
        >
            Mapa del sitio
        </div> -->
        <div 
            class="container_nav__item_menu" 
            :style="cssItem" 
            :class="$route.name == 'AboutSite' ? 'active_item_desktop':''" 
            @click="getRoute('AboutSite')"
            style="background: #0B6C37"
        >
            Mapa del sitio
        </div>
        <!-- <div 
            class="container_nav__item_menu" 
            :style="cssItem" 
            :class="$route.name == 'Faqs' ? 'active_item_desktop':''" 
            @click="getRoute('Faqs')"
            style="background: #1796BE"
        >
            Preguntas frecuentes
        </div> -->
        <div 
            class="container_nav__item_menu" 
            :style="cssItem" 
            :class="$route.name == 'Contact' ? 'active_item_desktop':''" 
            @click="getRoute('Contact')"
            style="background: #043C61"
        >
            Contáctanos
        </div>

        <div 
            class="container_nav__item_menu" 
            :style="cssItem" 
            :class="$route.name == 'Maps' ? 'active_item_desktop':''" 
            @click="getRoute('Maps')"
            style="background: #edbc2a"
        >
            Mapas
        </div>

        <div 
            class="container_nav__item_menu" 
            :style="cssItem" 
            
            @click="showTranslate=true"
            style="background: #043C61"
        >
            Traducir
        </div>



        <div style="width: 20%;margin-left: 11%;">
                <v-select 
                @input="redirect" 
                label="name" 
                placeholder="Buscar" 
                style="width: 100%; " 
                :options="dimensions"
                v-model="dimension"
                :dropdown-should-open="dropdownShouldOpen"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                    <img src="@/assets/img/icon-search.png">
                </span>
              </template>

                <template v-slot:no-options>Lo sentimos no existen coincidencias</template>
            </v-select>
        </div>

     
        <!-- <div 
            class="container_nav__item_menu" 
            :style="cssItem" 
            :class="$route.name == 'SubCategoryProduct' ? 'active_item_desktop':''" 
            @click="getRoute('SubCategoryProduct')"
            style="background: #D3312C"
        >
            Plan de Desarrollo
        </div> -->
    </div>
    <div class="container_nav__movil" >
        <div class="container_nav__movil--options">
            <div class="container_nav__movil--icon_menu" @click="openMenu" v-if="$route.name == 'Home' || $route.name == 'AboutObservatorio' || $route.name == 'Faqs' || $route.name == 'Contact' || $route.name == 'SubCategoryProduct' ">
                <img src="@/assets/img/icono-menu.svg" v-if="!menu_movil">
                <img src="@/assets/img/icono-cerrar-menu.svg" v-else>
            </div>
            <div class="container_nav__movil--icon_back" v-else @click="goBack()">
                <img src="@/assets/img/icono-volver.svg">
                <p>
                    Regresar
                </p>
            </div>
            <div class="container_nav__movil--logo" :style="{'background-image': `url(${observatorio.logo_img.url})`, 'background-size': 'contain', 'background-position': 'center', 'background-repeat':'no-repeat'}">

            </div>
            <div class="container_nav__movil--btn_search" @click="openSearch">
                <!-- <img src="@/assets/img/icono-buscar.svg" alt=""> -->
            </div>



        </div>
        <div class="container_nav__movil--items_menu" v-if="menu_movil">
            <div class="container_nav__movil--item" @click="getRoute('Home')" :class="$route.name == 'Home' ? 'active-item':''">
                Inicio
            </div>
            <div class="container_nav__movil--item" @click="getRoute('AboutObservatorio')" :class="$route.name == 'AboutObservatorio' ? 'active-item':''">
                ¿Qué es el Observatorio?
            </div>
            <!-- <div class="container_nav__movil--item" @click="getRoute('Faqs')" :class="$route.name == 'Faqs' ? 'active-item':''">
                Preguntas frecuentes
            </div> -->
            <div class="container_nav__movil--item">
                Mapa del sitio
            </div>
            <div class="container_nav__movil--item" @click="getRoute('Contact')" :class="$route.name == 'Contact' ? 'active-item':''">
                Contáctanos
            </div>

            <div class="container_nav__movil--item" :style="cssItem" :class="$route.name == 'SubCategoryProduct' ? 'active_item_desktop':''" @click="getRoute('SubCategoryProduct')">
                Plan de desarrollo
            </div>

            <div class="container_nav__movil--item" :style="cssItem" :class="$route.name == 'Maps' ? 'active_item_desktop':''" @click="getRoute('Maps')">
                Mapas
            </div>

            <div class="container_nav__movil--item" :style="cssItem"   @click="showTranslate=true">
                Traducir
            </div>

        </div>

       
    
        <!--<div class="container_nav__movil--input_search" v-if="input_search">
            <p>Buscar...</p>
        </div>-->
    </div>
    <div class="menu_mobie_active" v-if="menu_movil || input_search"></div>


    <b-modal id="bv-modal-translate" v-model="showTranslate" size="md" title="" ok-only ok-variant="secondary" ok-title="Cerrar" >
      
        
        <Translate/>

        <!-- <Translator/> -->
    </b-modal> 

</div>

</template>
<script>
// import Translator from "vue-gtranslate";
// import "vue-gtranslate/translator.css"
export default {
    data() {
        return {
            modalAdvertencia: false,
            observatorio: [],
            height: 30,
            menu_movil: false,
            input_search: false,
            observatorio:this.getClient(),
            showTranslate:false,
            dimensions: [
                {
                    name: 'Batería de indicadores'
                },
                {
                    name: 'Dimensiones plan de desarrollo'
                }
            ],
            data:'',
            dimension:null,
           
        }
    },

//     components: {
//     Translator
//   },

      mounted() {

   
  },

  
    methods: {
        
   
        goBack(){
            
            if(this.$route.name === 'Segments'){
                this.$router.push({name:'Dimension', params: { id: this.$route.params.id }})
            }else if(this.$route.name === 'Dimension'){
                this.$router.push({name:'Home'})
            }else {
                this.$router.go(-1);
            }
        },
        openMenu(){
            this.menu_movil = !this.menu_movil;
            this.input_search = false;
        },
        openSearch(){
            this.input_search = !this.input_search;
            this.menu_movil = false;
        },
        getRoute(name){
            this.menu_movil = false
            if (this.$route.name != name) {
                this.$router.push({name: name});
            }
        },

        dropdownShouldOpen(VueSelect) {
            if (this.dimension !== null) {
                return VueSelect.open
            }

             return VueSelect.search.length !== 0 && VueSelect.open
        },
        

        redirect(data){
            console.log('redirect', data)
            // this.$router.push({name: 'Dimension', params: {id: data.id}})
            if(data.name == 'Batería de indicadores'){
                this.$router.push({path: 'Indicators'})
            }else if(data.name == 'Dimensiones plan de desarrollo'){
                 this.$router.push({path: 'SubCategoryProduct'})
            }else if(data.isDimension == true){
                window.location.href = '/Dimension/' + data.dimensionId
            }else{
               window.location.href = '/Dimension/' + data.dimensionId + '?Category=' + data.segmentId

            }

            
            // this.$router.replace({name: 'Dimension', params: {id: data.dimensionId}})
            // if(this.$route.name == 'Dimension'){
                
            //     // window.location.href = 'Dimension/' + data.dimensionId
            //     this.$router.go(-1);
            //     this.$router.push({name: 'Dimension', params: {id: data.dimensionId}})
            // }else{
            //     this.$router.push({name: 'Dimension', params: {id: data.dimensionId}})
            // }
            // // this.$router.push({path: 'Dimension/' + data.id})
            // console.log('this.$router', this.$route.path)
            // // window.location.href = 'Dimension/' + data.id
        },

        getDimensionInfo(){

            this.$http.get('dimensions/townhall/'+this.getClient().id).then(response => {

                // console.log('response dimensions',response)

            response.data.forEach(element => {

                var data = {
                    dimensionId: element.id,
                    name: element.name_dimension,
                    isDimension: true
                }

                 this.dimensions.push(data)

                 element.segments.forEach(element2 => {
                    // console.log('element2', element2)

                    var search = this.dimensions.find(item => item.name == element2.name_architecture)

                    if(!search){
                        var data = {
                            dimensionId: element2.dimension_id,
                            name: element2.name_architecture,
                            isDimension: false,
                            segmentId: element2.id
                        }

                        this.dimensions.push(data)
                    }
                });
            });

            console.log('this.dimensions',this.dimensions)
    

            }).catch(error => {
                //console.log('ERROR AL CONSULTAR LAS DIMENSIONES', error)
            })
     },

    },
    computed: {
        cssItem(){
            return {
                '--border-bottom': this.observatorio.primary_color != null ? this.observatorio.primary_color : '#48C5B6'
            }
        },

    },
    beforeMount() {
        this.getDimensionInfo()
        this.observatorio = this.getClient();
    }
}
</script>

<style >
.vs__dropdown-toggle{
    height: 80% !important;
    border-radius: 0px 0px 10px 10px !important;
}
.vs__open-indicator{
    transform: rotate(0deg) !important;
}
</style>
