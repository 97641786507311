<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="58" height="60" viewBox="0 0 46.014 60">
    <g transform="translate(-6.991)">
        <path :fill="color" class="a" d="M52,7.984C37.372,7.984,30.829.416,30.766.342a1.034,1.034,0,0,0-1.536,0c-.064.076-6.539,7.642-21.238,7.642a1,1,0,0,0-1,1V28.075A33.833,33.833,0,0,0,19.541,54.6a30.511,30.511,0,0,0,10.172,5.354.988.988,0,0,0,.57,0A30.529,30.529,0,0,0,40.455,54.6,33.835,33.835,0,0,0,53,28.075V8.985A1,1,0,0,0,52,7.984ZM51,28.075A31.821,31.821,0,0,1,39.219,53.032,28.634,28.634,0,0,1,30,57.954a28.614,28.614,0,0,1-9.221-4.922A31.819,31.819,0,0,1,8.992,28.075V9.974C21.237,9.7,27.862,4.475,30,2.427,32.134,4.476,38.759,9.7,51,9.974Z" transform="translate(0 0)" />
        <path :fill="color" class="a" d="M29.434,7.986a35.545,35.545,0,0,1-15.569,5.635,1,1,0,0,0-.874.992V28.074A27.792,27.792,0,0,0,23.248,49.885a25.367,25.367,0,0,0,6.383,3.7A1,1,0,0,0,31,52.657V8.813a1,1,0,0,0-1.562-.827Z" transform="translate(0.002 0.002)" />
    </g>
    </svg>
</template>
<script>
export default {
    props:{
        color: {
            default: '#A5D271',
            type: String
        }
    }
}
</script>