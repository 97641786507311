<template>
    <div :show="showBuilding" class="b-overlay-wrap position-relative" style="margin-bottom:3%">
        <!-- <div class="info_banner" :style="{backgroundImage: 'url(' + program.imagen.url + ')'}">
            <div class="info_banner__body">
                <div class="info_banner__body--title">
                    {{program.name}}
                </div>
                <div class="info_banner__body--text">
                    {{program.subtitle}}
                </div>
                <button 
                    class="info_banner__body--button"
                    @click="goToProgram(program.id)"
                >
                    Conoce más
                    <ArrowUpIcon 
                        class="button_icon" 
                        color="#043C61" 
                        width="16" 
                        height="8"
                    />
                </button>
            </div>
        </div> -->

        <!-- <div class="info_banner" :style="{backgroundImage: 'url(' + program.imagen.url + ')'}">
            <div class="info_banner__body">

            </div>
        </div> -->


        <carousel :per-page="1"  :mouse-drag="true" paginationPosition="bottom-overlay" :paginationPadding="16" :paginationSize="14" :autoplay="true" >
            <slide>
                <img src="@/assets/img/banners/banner_vista_principal.png" class="img-content" v-if="lang == 'es'">
                <img src="@/assets/img/banners/Be-part-of.png" class="img-content" v-if="lang == 'en'">
            </slide>

            <slide style="cursor: pointer;">
                <a @click="showModal">
                    <img src="@/assets/img/banners/Banner final_rutas2.png" class="img-content" v-if="lang == 'es'">
                    <img src="@/assets/img/banners/Routes-grande.png" class="img-content" v-if="lang == 'en'">
                </a>
            </slide>
        </carousel>




        <b-modal id="bv-modal-ruta" v-model="show" size="xl" title="" ok-only ok-variant="secondary" ok-title="Cerrar" >
          <template #modal-header>
            <div class="mx-auto">
                <h1>Rutas turísticas</h1>
            </div>
          </template>
          
          <b-container class="content-routes">
            <b-row>
                <b-col>
                    <div class="content-routes__card">
                        <!-- <a href="/pdf/Ruta Miradores.pdf" download>
                            <img src="@/assets/img/icono-miradores.png"  class="content-routes__card--img">
                        </a>     -->
                        <a @click="modalPDF(1)">
                            <img src="@/assets/img/icono-miradores.png"  class="content-routes__card--img">
                        </a>  
                    </div>
                </b-col>
                <b-col>
                    <div class="content-routes__card">
                        <!-- <a href="/pdf/Ruta Sendero Ecologico.pdf" download>
                            <img src="@/assets/img/icono-sendero-ecologico.png" class="content-routes__card--img">
                        </a>     -->
                        <a @click="modalPDF(2)">
                            <img src="@/assets/img/icono-sendero-ecologico.png" class="content-routes__card--img">
                        </a>
                    </div>
                </b-col>
                <b-col>
                    <div class="content-routes__card">
                        <!-- <a href="/pdf/Ruta vereda El Carmen.pdf" download>
                            <img src="@/assets/img/icono-el-carmen.png" class="content-routes__card--img">
                        </a>     -->
                        <a @click="modalPDF(3)">
                            <img src="@/assets/img/icono-el-carmen.png" class="content-routes__card--img">
                        </a> 
                    </div>
                </b-col>
            </b-row>
            
            <b-row>
                <b-col>
                    <div class="content-routes__card">
                        <!-- <a href="/pdf/Ruta Gastronomica.pdf" download>
                            <img src="@/assets/img/icono-gastronomica.png" class="content-routes__card--img">
                        </a> -->
                         <a @click="modalPDF(4)">
                            <img src="@/assets/img/icono-gastronomica.png" class="content-routes__card--img">
                        </a>
                    </div>
                </b-col>
                <b-col>
                    <div class="content-routes__card">
                        <!-- <a href="/pdf/Ruta los Tabacales.pdf" download>
                            <img src="@/assets/img/icono-tabacales.png" class="content-routes__card--img">
                        </a> -->
                        <a @click="modalPDF(5)">
                            <img src="@/assets/img/icono-tabacales.png" class="content-routes__card--img">
                        </a>
                    </div>
                </b-col>
                <b-col>
                    <div class="content-routes__card">
                        <!-- <a href="/pdf/Ruta Religiosa.pdf" download>
                            <img src="@/assets/img/icono-religiosa.png" class="content-routes__card--img">
                        </a> -->
                        <a @click="modalPDF(6)">
                            <img src="@/assets/img/icono-religiosa.png" class="content-routes__card--img">
                        </a>
                    </div>
                </b-col>
            </b-row>
           
            <b-row>
                <b-col>
                    <div class="content-routes__card">
                        <!-- <a href="/pdf/Ruta Cafetera.pdf" download>
                            <img src="@/assets/img/icono-cafetera.png" class="content-routes__card--img">
                        </a> -->
                        <a @click="modalPDF(7)">
                            <img src="@/assets/img/icono-cafetera.png" class="content-routes__card--img">
                        </a>
                    </div>
                </b-col>
                <b-col>
                    <div class="content-routes__card">
                        <!-- <a href="/pdf/Ruta Ecologica.pdf" download>
                            <img src="@/assets/img/icono-ecologica.png" class="content-routes__card--img">
                        </a> -->
                        <a @click="modalPDF(8)">
                            <img src="@/assets/img/icono-ecologica.png" class="content-routes__card--img">
                        </a>
                    </div>
                </b-col>
                <b-col>
                    <div class="content-routes__card">
                        <!-- <a href="/pdf/Ruta Murales.pdf" download>
                            <img src="@/assets/img/icono-murales.png" class="content-routes__card--img">
                        </a> -->
                        <a @click="modalPDF(9)">
                            <img src="@/assets/img/icono-murales.png" class="content-routes__card--img">
                        </a>
                    </div>
                </b-col>
            </b-row>
          </b-container>

        </b-modal>


        <b-modal id="bv-modal-rutaDetalle" v-model="showPDF" size="xl" title="" ok-only ok-variant="secondary" ok-title="Cerrar" >
          <pdf :src="pdf"></pdf>

        </b-modal> 

        
    </div>
</template>
<script>
import pdf from 'vue-pdf'
export default {

    components: {
        pdf
    },

    name: 'InfoBanner',
    props: {
        bannerImg: {
            type: String,
            default: ''
        },
        heigth: {
            type: String,
            default: '50px'
        }
    },
    data(){
        return {
            // banner: bannerImg
            showBuilding:true,
            program:[],
            slide: 0,
            sliding: null,
            show: false,
            showPDF: false,
            currentPage: 0,
            pageCount: 0,
            numPages: undefined,
            imgSelect:'',
            pdf:'',
            lang:''
  
        }
    },

    created(){
        this.lang = localStorage.getItem('lang')
        console.log('this.lang infobaner', this.lang)
    },

    methods:{

        modalPDF(data){

            var loadingTask

                switch (data) {
                    case 1:
                        loadingTask = pdf.createLoadingTask('/pdf/Ruta Miradores.pdf');
                        this.pdf = loadingTask
                        break;
                    case 2:
                        loadingTask = pdf.createLoadingTask('/pdf/Ruta Sendero Ecologico.pdf');
                        this.pdf = loadingTask
                        break;
                    case 3:
                        loadingTask = pdf.createLoadingTask('/pdf/Ruta vereda El Carmen.pdf');
                        this.pdf = loadingTask
                        break;
                    case 4:
                        loadingTask = pdf.createLoadingTask('/pdf/Ruta Gastronomica.pdf');
                        this.pdf = loadingTask
                        break;
                    case 5:
                        loadingTask = pdf.createLoadingTask('/pdf/Ruta los Tabacales.pdf');
                        this.pdf = loadingTask
                        break;
                    case 6:
                        loadingTask = pdf.createLoadingTask('/pdf/Ruta Religiosa.pdf');
                        this.pdf = loadingTask
                        break;
                    case 7:
                        loadingTask = pdf.createLoadingTask('/pdf/Ruta Cafetera.pdf');
                        this.pdf = loadingTask
                        break;
                    case 8:
                        loadingTask = pdf.createLoadingTask('/pdf/Ruta Ecologica.pdf');
                        this.pdf = loadingTask
                        break;
                    case 9:
                        loadingTask = pdf.createLoadingTask('/pdf/Ruta Murales.pdf');
                        this.pdf = loadingTask
                        break;                                
                
                    default:
                        break;
                }


            this.showPDF = true
        },

        showModal(){
            this.show = true
        },

        onSlideStart(slide) {
            this.sliding = true
        },
        onSlideEnd(slide) {
            this.sliding = false
        },

        getProgram(){
            this.$http.get("programs/townhall/"+this.getClient().id)
            .then((response)=>{
                this.program = response.data
                console.log('aqui esta el ',this.program)
                this.showBuilding = false;
            })
        },
        goToProgram(id){
            this.$router.push({name:'Develop',params:{id:id}})
        }
    },
    beforeMount(){
        this.getProgram()
    }    
    
}
</script>

<style lang="scss" scoped>

.info_banner{
    max-width: 100%;
    min-height: 450px;
    //background-image: url(../../assets/img/banners/BannerObservatorio_01.png);
    margin-bottom: 50px;
    background-size: cover;
}


.img-content{
    object-fit: cover;
    width:100%;
    height:100%;
    border-radius:15px;
}



.VueCarousel-navigation-button[data-v-453ad8cd]{
    margin-left: 5%;
}

.VueCarousel-pagination{
    margin-top: -4%;
}

</style>