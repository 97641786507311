<template>
    <div class="container_dimension mb-5 subCategorys">
        <div class="container_dimension__menu--fixed">
            <div class="container_dimension__menu">
                <div class="container_dimension__menu--header">
                <div
                    class="container_dimension__menu--btnBack"
                    :style="{ 'background-color': getClient().primary_color }"
                    @click="$router.push({ name: 'Home' })"
                >
                    <div class="btnBack__arrow">
                    <img src="@/assets/img/arrow-back.svg" />
                    </div>
                    <div class="btnBack__text">Regresar</div>
                </div>
                <div class="container_dimension__menu--dimension">
                    SECRETARÍAS
                </div>
                </div>

                <div class="container_dimension__menu--list">
                    <div
                        class="container_dimension__item"
                        v-for="(value, key) in secretarys"
                        :key="key"
                    >
                        <a 
                            class="container_dimension__link item_segment"
                            :href="'#Category-' + value.id">
                            {{ value.name }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import $ from "jquery";

    export default {
        beforeMount() {
            this.getClient()
        },
        props: {
            secretarys: {
                type: Array,
                default: () => []
            }
        },
        name: 'MainDimension',
        data() {
            
            return {
                collapseSegments: [],
                toggle: false,
            }
        }, 
       mounted(){
        $(this).ready(function () {

            $(this).on("click", ".item_segment", function () {
                var target = $(this).attr("href");
                $("body, html").animate(
                {
                    scrollTop: $(target).offset().top,
                },300 );
                return false;
            });
        });

        $(window).bind("scroll", function () {
            var currentTop = $(window).scrollTop();
            var elems = $(".scrollspy");
            elems.each(function (index) {
                var elemTop = $(this).offset().top;
                var elemBottom = elemTop + $(this).height();
                if (currentTop >= elemTop && currentTop <= elemBottom) {
                var id = $(this).attr("id");
                var navElem = $('a[href="#' + id + '"]');
                $(".item_segment").removeClass("active_segment");
                navElem.addClass("active_segment");
                var name = navElem.parent().attr("id");

                $(".collapse").hide();
                $("#" + name).css("display", "flex");

                for (let index = 0; index < vm.collapseSegments.length; index++) {
                        if (vm.collapseSegments[index].name == name) {
                            vm.collapseSegments[index].toggle = true;
                        } else {
                            vm.collapseSegments[index].toggle = false;
                        }
                    }
                }
            });
        });
    
        }
    }
</script>