<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
        <path :fill="color" class="a" d="M44.727,37.218V15.983a.977.977,0,0,0-.4-.79L41.914,13.44l1.9-1.186a.976.976,0,0,0,.072-1.606L42.122,9.313V8.691H43.75a.976.976,0,0,0,.977-.977V5.762A5.768,5.768,0,0,0,38.965,0H5.664L5.635,0,5.569,0A5.057,5.057,0,0,0,1.546,2.139,7.963,7.963,0,0,0,0,6.973c0,3.263,2.136,5.918,4.761,5.918h5.4V28.369a.976.976,0,0,0,.445.819l1.915,1.241L10.49,32.247a.977.977,0,0,0-.326.728v8.463a.9.9,0,0,1,0,.309A9.381,9.381,0,0,0,12.019,47.8,5.981,5.981,0,0,0,16.713,50H43.555a6.445,6.445,0,0,0,1.172-12.782ZM8.015,10.938A3.91,3.91,0,0,0,8.466,9.1,3.533,3.533,0,0,0,4.919,5.273a.977.977,0,0,0,0,1.953c1,0,1.594.7,1.594,1.871a1.757,1.757,0,0,1-1.751,1.84c-1.522,0-2.808-1.816-2.808-3.965,0-2.768,1.622-5.019,3.616-5.019A4.072,4.072,0,0,1,8.776,3.228C9.7,4.27,10.164,5.96,10.164,8.252v2.686Zm5.511,35.615a7.487,7.487,0,0,1-1.409-4.8.9.9,0,0,1,0-.309V33.412l2.661-2.378a.977.977,0,0,0-.119-1.548l-2.542-1.648V8.252c0-2.771-.627-4.889-1.862-6.3H38.965a3.813,3.813,0,0,1,3.809,3.809v.977H41.146a.977.977,0,0,0-.977.977V9.8a.977.977,0,0,0,.387.779l1.019.772-1.923,1.2a.976.976,0,0,0-.057,1.618l3.178,2.312V37.109H17.946a.971.971,0,0,0-.142.011c-.094-.007-.188-.011-.284-.011a3.71,3.71,0,0,0-3.7,3.793,3.533,3.533,0,0,0,3.547,3.824.977.977,0,1,0,0-1.953c-1,0-1.594-.7-1.594-1.871a1.757,1.757,0,0,1,1.751-1.84c1.522,0,2.808,1.816,2.808,3.965,0,2.768-1.622,5.019-3.615,5.019A4.006,4.006,0,0,1,13.526,46.553Zm30.029,1.494H20.585c.05-.061.1-.122.15-.186a7.894,7.894,0,0,0,1.54-4.5H37.5a.977.977,0,0,0,0-1.953H22.1a6.425,6.425,0,0,0-1.047-2.344h22.5a4.492,4.492,0,0,1,0,8.984Zm0,0" />
        <path :fill="color" class="a" d="M180.336,79.953a.977.977,0,1,0-.69-.286A.986.986,0,0,0,180.336,79.953Zm0,0" transform="translate(-161.844 -70.383)" />
        <path :fill="color" class="a" d="M222.977,79.953h14.1a.977.977,0,0,0,0-1.953h-14.1a.977.977,0,0,0,0,1.953Zm0,0" transform="translate(-200.32 -70.383)" />
        <path :fill="color" class="a" d="M180.332,135.953h18.262a.977.977,0,0,0,0-1.953H180.332a.977.977,0,0,0,0,1.953Zm0,0" transform="translate(-161.84 -120.914)" />
        <path :fill="color" class="a" d="M180.332,191.953h18.262a.977.977,0,0,0,0-1.953H180.332a.977.977,0,0,0,0,1.953Zm0,0" transform="translate(-161.84 -171.445)" />
        <path :fill="color" class="a" d="M180.332,247.953h18.262a.977.977,0,0,0,0-1.953H180.332a.977.977,0,0,0,0,1.953Zm0,0" transform="translate(-161.84 -221.977)" />
        <path :fill="color" class="a" d="M180.332,303.953h18.262a.977.977,0,0,0,0-1.953H180.332a.977.977,0,0,0,0,1.953Zm0,0" transform="translate(-161.84 -272.508)" />
        <path :fill="color" class="a" d="M413.848,424a.977.977,0,1,0,.69.286A.984.984,0,0,0,413.848,424Zm0,0" transform="translate(-372.552 -382.594)" />
    </svg>
</template>
<script>
export default {
    props:{
        color: {
            default: '#6F73BF',
            type: String
        }
    }
}
</script>