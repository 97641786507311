import Vue from 'vue'
import Vuex from 'vuex'


import client from './modules/client'
import graph from './modules/graphs'
import  createPersistedState  from  'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    client: client,
    graph: graph,
  },
  plugins: [createPersistedState()],
})