<template>
    <div class="container__icons--dimension" v-if="getClient().id == 1">
        
        <div class="container__icons--dimension__price" v-if="dimension==1">
            <div class="text">
                <p>El presupuesto para esta dimensión para los años 2020-2023 es de</p>
            </div>
            <div class="price">
                <div></div><p>$73.066'000.000</p><div></div>
            </div>
        </div>
        <div class="container__icons--dimension__price" v-if="dimension==2">
            <div class="text">
                <p>El presupuesto para esta dimensión para los años 2020-2023 es de</p>
            </div>
            <div class="price">
                <div></div><p>$18.795'000.000</p><div></div>
            </div>
        </div>
        <div class="container__icons--dimension__price" v-if="dimension==3">
            <div class="text">
                <p>El presupuesto para esta dimensión para los años 2020-2023 es de</p>
            </div>
            <div class="price">
                <div></div><p>$50.490'000.000</p><div></div>
            </div>
        </div>
        <div class="container__icons--dimension__price" v-if="dimension==4">
            <div class="text">
                <p>El presupuesto para esta dimensión para los años 2020-2023 es de</p>
            </div>
            <div class="price">
                <div></div><p>$8.999'000.000</p><div></div>
            </div>
        </div>
        <div class="container__icons--dimension__price" v-if="dimension==5">
            <div class="text">
                <p>El presupuesto para esta dimensión para los años 2020-2023 es de</p>
            </div>
            <div class="price">
                <div></div><p>$8.922'000.000</p><div></div>
            </div>
        </div>

        <div class="container__icons--dimension__title" v-if="dimension==1">
            <p>Esta dimensión se compone de 9 líneas estratégicas y el presupuesto para los años 2020-2023 es de:</p>
        </div>
        <div class="container__icons--dimension__title" v-if="dimension==2">
            <p>Esta dimensión se compone de 4 líneas estratégicas y el presupuesto para los años 2020-2023 es de:</p>
        </div>
        <div class="container__icons--dimension__title" v-if="dimension==3">
            <p>Esta dimensión se compone de 9 líneas estratégicas y el presupuesto para los años 2020-2023 es de:</p>
        </div>
        <div class="container__icons--dimension__title" v-if="dimension==4">
            <p>Esta dimensión se compone de 3 líneas estratégicas y el presupuesto para los años 2020-2023 es de:</p>
        </div>
        <div class="container__icons--dimension__title" v-if="dimension==5">
            <p>Esta dimensión se compone de 3 líneas estratégicas y el presupuesto para los años 2020-2023 es de:</p>
        </div>
        
        <div class="container__icons--dimension__icons" v-if="dimension==1">
            <div v-for="(icon,index) in icons" :key="index+'icons'">
                <a :href="'#Linea-' + index">
                    <img :src="icon.img" alt="">
                </a>
            </div>
        </div>
        <div class="container__icons--dimension__icons" v-if="dimension==2">
            <div v-for="(icon, index) in icons1" :key="index+'icons1'">
                <a :href="'#Linea-' + index">
                    <img :src="icon.img" alt="">
                </a>
            </div>
        </div>
        <div class="container__icons--dimension__icons" v-if="dimension==3">
            <div v-for="(icon, index) in icons2" :key="index+'icons2'">
                <a :href="'#Linea-' + index">
                    <img :src="icon.img" alt="">
                </a>
            </div>
        </div>
        <div class="container__icons--dimension__icons" v-if="dimension==4">
            <div v-for="(icon, index) in icons3" :key="index+'icons3'">
                <a :href="'#Linea-' + index">
                    <img :src="icon.img" alt="">
                </a>
            </div>
        </div>
        <div class="container__icons--dimension__icons" v-if="dimension==5">
            <div v-for="(icon, index) in icons4" :key="index+'icons4'">
                <a :href="'#Linea-' + index">
                    <img :src="icon.img" alt="">
                </a>
            </div>
        </div>
        
        <!-- <div class="container__table">
            <table>
                <thead>
                    <tr class="container__table--titles__table">
                    <th v-for="field in xlsxData.titles" :key="field">{{field.title}}</th>
                    </tr>
                </thead>
                <tbody  v-if="dimension==1">
                    <tr class="container__table--green__zone" >
                        <td v-for="(data , index) in xlsxData.dimension1[0].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension1[1].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension1[2].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension1[3].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension1[4].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension1[5].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension1[6].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension1[7].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension1[8].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension1[9].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>                  
                </tbody>
                <tbody  v-if="dimension==2">
                    <tr class="container__table--green__zone" >
                        <td v-for="(data , index) in xlsxData.dimension2[0].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension2[1].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension2[2].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension2[3].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension2[4].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>         
                </tbody>
                <tbody  v-if="dimension==3">
                    <tr class="container__table--green__zone" >
                        <td v-for="(data , index) in xlsxData.dimension3[0].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension3[1].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension3[2].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension3[3].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension3[4].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension3[5].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension3[6].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension3[7].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension3[8].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension3[9].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>                  
                </tbody>
                <tbody  v-if="dimension==4">
                    <tr class="container__table--green__zone" >
                        <td v-for="(data , index) in xlsxData.dimension4[0].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension4[1].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension4[2].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension4[3].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>             
                </tbody>
                <tbody  v-if="dimension==5">
                    <tr class="container__table--green__zone" >
                        <td v-for="(data , index) in xlsxData.dimension5[0].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension5[1].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension5[2].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>
                    <tr class="container__table--squares">
                        <td v-for="(data , index) in xlsxData.dimension5[3].data" :key="data" :style="{'background': index == 0 ? '#e2e0e0' : '#ffffff','text-align': index == 0 ? 'left' : 'right'}">{{data}}</td>
                    </tr>                 
                </tbody>
            </table>
        </div> -->

    </div>
</template>
<script>
export default {
    props:{
    dimension:{
              type:Number|String
            },
    },
    data(){
        return{
            icons:[
                {img:require('../../assets/img/graficos_etiquetas/D1 - 1.png')},
                {img:require('../../assets/img/graficos_etiquetas/D1 - 2.png')},
                {img:require('../../assets/img/graficos_etiquetas/D1 - 3.png')},
                {img:require('../../assets/img/graficos_etiquetas/D1 - 4.png')},
                {img:require('../../assets/img/graficos_etiquetas/D1 - 5.png')},
                {img:require('../../assets/img/graficos_etiquetas/D1 - 6.png')},
                {img:require('../../assets/img/graficos_etiquetas/D1 - 7.png')},
                {img:require('../../assets/img/graficos_etiquetas/D1 - 8.png')},
                {img:require('../../assets/img/graficos_etiquetas/D1 - 9.png')},
            ],
            icons1:[
                {img:require('../../assets/img/graficos_etiquetas/D2 - 1.png')},
                {img:require('../../assets/img/graficos_etiquetas/D2 - 2.png')},
                {img:require('../../assets/img/graficos_etiquetas/D3 - 3.png')},
                {img:require('../../assets/img/graficos_etiquetas/D4 - 4.png')},
            ],
            icons2:[
                {img:require('../../assets/img/graficos_etiquetas/D3 - 1.png')},
                {img:require('../../assets/img/graficos_etiquetas/D3 - 2.png')},
                {img:require('../../assets/img/graficos_etiquetas/D3 - 3-1.png')},
                {img:require('../../assets/img/graficos_etiquetas/D3 - 4.png')},
                {img:require('../../assets/img/graficos_etiquetas/D3 - 5.png')},
                {img:require('../../assets/img/graficos_etiquetas/D3 - 6.png')},
                {img:require('../../assets/img/graficos_etiquetas/D3 - 7.png')},
                {img:require('../../assets/img/graficos_etiquetas/D3 - 8.png')},
                {img:require('../../assets/img/graficos_etiquetas/D3 - 9.png')},
            ],
            icons3:[
                {img:require('../../assets/img/graficos_etiquetas/D4 - 1.png')},
                {img:require('../../assets/img/graficos_etiquetas/D4 - 2.png')},
                {img:require('../../assets/img/graficos_etiquetas/D4 - 3.png')},
                
            ],
            icons4:[
                {img:require('../../assets/img/graficos_etiquetas/D5 - 1.png')},
                {img:require('../../assets/img/graficos_etiquetas/D5 - 2.png')},
                {img:require('../../assets/img/graficos_etiquetas/D5 - 3.png')},
            ],
            xlsxData:{
                    titles:[
                        {title:'ESTRUCTURA DEL PLAN'},
                        {title:'SUMA DE TOTAL 2020 - 2023'},
                        {title:'TOTAL 2020'},
                        {title:'TOTAL 2021'},
                        {title:'TOTAL 2022'},
                        {title:'TOTAL 2023'}
                        
                    ],
                dimension1:[
                    {
                        data:[
                            'El Retiro con la familia siempre',
                            "$73'066.000.000",
                            '$17.066.000.000',
                            "$19'025.000.000",
                            "$18'174.000.000",
                            "$18'763.000.000"  
                        ]
                    },
                    {
                        data:[
                            'Infancia y adolescencia',
                            "$2'195.000.000",
                            "$549'000.000",
                            "$549'000.000",
                            "$549'000.000",
                            "$549'000.000",
                        ]   
                    },
                    {
                        
                        data:[
                            'Juventud',
                            "$1.273'000.000",
                            "$318'000.000",
                            "$318'000.000",
                            "$318'000.000",
                            "$318'000.000"                            
                        ]
                    },
                    {
                        data:[
                            'Equidad de género',
                            "$3.330'000.000",
                            "$832'000.000",
                            "$832'000.000",
                            "$833'000.000",
                            "$833'000.000"
                        ]
                    },
                    {
                       
                        data:[
                            'Discapacidad',
                            "$2.568'000.000",
                            "$642'000.000",
                            "$642'000.000",
                            "$643'000.000",
                            "$643'000.000",
                        ]
                    }, 
                    {
                        
                        data:[
                            'Envejecimiento y Vejez',
                            "$3.964'000.000",
                            "$991'000.000",
                            "$991'000.000",
                            "$991'000.000",
                            "$991'000.000",
                        ]
                    },
                    {
                        data:[
                            'Salud',
                            "$11.638'000.000",
                            "$3.669'000.000",
                            "$2.653'000.000",
                            "$2.656'000.000",
                            "$2.659'000.000",
                        ]
                    },
                    {
                        data:[
                            'Educación',
                            "$24.423'000.000",
                            "$5.063'000.000",
                            "$7.438'000.000",
                            "$5.793'000.000",
                            "$6.129'000.000",
                        ]
                    },
                    {
                        data:[
                            'Cultura',
                            "$11.381'000.000",
                            "$2.286'000.000",
                            "$2.808'000.000",
                            "$3.153'000.000",
                            "$3.134'000.000",
                        ]
                    },
                    {
                        data:[
                            'Deporte, actividad física y recreación',
                            "$12.294'000.000",
                            "$2.755'000.000",
                            "$2.749'000.000",
                            "$3.240'000.000",
                            "$3.507'000.000"
                        ]
                    }
                ],
                dimension2:[
                    {data:[
                        "El Retiro productivo y competitivo, siempre",
                        "$18.795'000.000",
                        "$5.184'000.000",
                        "$4.396'000.000",
                        "$4.470'000.000",
                        "$4.746'000.000"
                    ]},
                    {data:[
                        "Equidad con el campo",
                        "$5.201'000.000",
                        "$1.301'000.000",
                        "$1.300'000.000",
                        "$1.300'000.000",
                        "$1.300'000.000"
                    ]},
                    {data:[
                        "Desarrollo empresarial",
                        "$3.706'000.000",
                        "$927'000.000",
                        "$927'000.000",
                        "$927'000.000",
                        "$927'000.000"
                    ]},
                    {data:[
                        "Turismo sostenible",
                        "$5.240'000.000",
                        "$1.310'000.000",
                        "$1.310'000.000",
                        "$1.310'000.000",
                        "$1.310'000.000"
                    ]},
                    {data:[
                        "Ciencia y tegnología e innovación",
                        "$4.648'000.000",
                        "$1.646'000.000",
                        "$859'000.000",
                        "$933'000.000",
                        "$1.209'000.000"
                    ]}
                ],
                dimension3:[
                    {data:[
                        "El Retiro sostenible, siempre",
                        "$50.490'000.000",
                        "$9.015'000.000",
                        "$14.268'000.000",
                        "$14.573'000.000",
                        "$12.634'000.000"
                    ]},
                    {data:[
                        "Ordenamiento territorial",
                        "$4.862'000.000",
                        "$241'000.000",
                        "$1.641'000.000",
                        "$1.491'000.000",
                        "$1.491'000.000"
                    ]},
                    {data:[
                        "Habitat sostenible",
                        "$7.374'000.000",
                        "$1.728'000.000",
                        "$1.959'000.000",
                        "$2.038'000.000",
                        "$1.649'000.000"
                    ]},
                    {data:[
                        "Medio ambiente",
                        "$13.668'000.000",
                        "$2.070'000.000",
                        "$3.697'000.000",
                        "$4.498'000.000",
                        "$3.403'000.000"
                    ]},
                    {data:[
                        "Servicios públicos",
                        "$3.801'000.000",
                        "$650'000.000",
                        "$1.250'000.000",
                        "$950'000.000",
                        "$950'000.000"
                    ]},
                    {data:[
                        "Protección animal",
                        "$968'000.000",
                        "$242'000.000",
                        "$242'000.000",
                        "$242'000.000",
                        "$242'000.000"
                    ]},
                    {data:[
                        "Movilidad",
                        "$11.555'000.000",
                        "$1.566'000.000",
                        "$3.563'000.000",
                        "$3.413'000.000",
                        "$3.013'000.000"
                    ]},
                    {data:[
                        "Espacio público",
                        "$2.480'000.000",
                        "$700'000.000",
                        "$730'000.000",
                        "$600'000.000",
                        "$450'000.000"
                    ]},
                    {data:[
                        "Cambio climático",
                        "$142'000.000",
                        "$63'000.000",
                        "$46'000.000",
                        "$26'000.000",
                        "$7'000.000"
                    ]},
                    {data:[
                        "Gestión de riesgo",
                        "$5.640'000.000",
                        "$1.755'000.000",
                        "$1.140'000.000",
                        "$1.315'000.000",
                        "$1.430'000.000"
                    ]}
                ],
                dimension4:[
                    {data:[
                        "El Retiro seguro y en paz, siempre",
                        "$8.999'000.000",
                        "$1.420'000.000",
                        "$2.846'000.000",
                        "$2.970'000.000",
                        "$1.764'000.000"
                    ]},
                    {data:[
                        "Seguridad y orden público",
                        "$6.518'000.000",
                        "$795'000.000",
                        "$2.229'000.000",
                        "$2.351'000.000",
                        "$1.143'000.000"
                    ]},
                    {data:[
                        "Garantías de convivencia ciudadana",
                        "$349'000.000",
                        "$89'000.000",
                        "$85'000.000",
                        "$87'000.000",
                        "$89'000.000"
                    ]},
                    {data:[
                        "Paz y derechos humanos",
                        "$2.132'000.000",
                        "$536'000.000",
                        "$532'000.000",
                        "$532'000.000",
                        "$532'000.000"
                    ]}
                ],
                dimension5:[
                    {data:[
                        "El Retiro con gestión pública efectiva, siempre",
                        "$8.922'000.000",
                        "$2.251'000.000",
                        "$2.467'000.000",
                        "$2.164'000.000",
                        "$2.040'000.000"
                    ]},
                    {data:[
                        "Buen gobierno",
                        "$3.452'000.000",
                        "$933'000.000",
                        "$1.078'000.000",
                        "$783'000.000",
                        "$659'000.000"
                    ]},
                    {data:[
                        "Participación ciudadana",
                        "$4.266'000.000",
                        "$1.078'000.000",
                        "$1.061'000.000",
                        "$1.063'000.000",
                        "$1.063'000.000"
                    ]},
                    {data:[
                        "Transparencia",
                        "$1.204'000.000",
                        "$240'000.000",
                        "$327'000.000",
                        "$318'000.000",
                        "$318'000.000"
                    ]}
                ],
                
            }
        }
    },
    beforeMount(){
        //console.log('Los iconos :::: ',this.icons)
        //console.log('Los clientes ::: ', this.getClient().id)
        //console.log('LA DATA ::::: ',this.xlsxData)
        //console.log('EPICO ::::: ',this.xlsxData[0].dimension1)
    }
}
</script>
<style scoped>
.container__icons--dimension{
    width: 100%;
    background: #FFFFFF;
    
}
.container__icons--dimension__title p{
    padding:30px 28px 22px 30px;

}
.container__icons--dimension__icons{
    width: 95%;
    margin: 35px;
    display:grid;
    grid-template-columns: repeat(5,1fr);
    padding-bottom: 30px;
}
.container__icons--dimension__icons img{
    width: 150px;
    object-fit:scale-down;
    margin-bottom: 15px;
}
.container__icons--dimension__price{
    display: flex;
    justify-content: center;
    align-items:center ;
    flex-wrap: wrap;
    margin-bottom: 40px;
    
    padding-top: 30px;
}
.container__icons--dimension__price p{
    padding: 0 45px;
}
.text{
    height: 50px;
    width: 444px;
    background: #D8D8D8;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.text p{
    
    text-align: center;
    margin: auto auto;
    
}
.price{
    display: flex;
    justify-content: center;
    align-content: center;
    height: 55px;
    width: 444px;
    background: #69C061;
    color: white;
    font-size: 32px;
    text-align: center;
    border-radius:  0 0 20px 20px;
    margin-top: 3px;
}
.price div{
    height: 1px;
    width: 1px;
    border: 3px solid white;
    border-radius: 50%;
    margin: auto auto;
}
.container__table{
    margin:35px;
    width: auto;
    height: 313px;
    border-radius: 10px;
}
.container__table table thead tr th{
    width: auto;
    height: auto;
    font-size: 10px;
    text-align: center;
    border: 0.5px solid gray;
}
.container__table table tbody tr td{
    width: 135.16px;
    height:auto;
    font-size: 10px;
    border: 0.5px solid gray;
}
.container__table--titles__table{
    background-color: #b3b3b3;
}
.container__table--squares{
    text-align: right;
}
.container__table--green__zone td{
    background-color: #69C061 !important;
    color: white;
    text-align: right;
}
</style>
