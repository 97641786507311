<template>
    <div class="container__icons--dimension" v-if="getClient().id == 22">
        
        <div class="container__icons--dimension__price" v-if="dimension==48">
            <div class="text">
                <p>El presupuesto para esta dimensión para los años 2020-2023 es de</p>
            </div>
            <div class="price">
                <div></div><p>$73.066'000.000</p><div></div>
            </div>
        </div>
        <div class="container__icons--dimension__price" v-if="dimension==49">
            <div class="text">
                <p>El presupuesto para esta dimensión para los años 2020-2023 es de</p>
            </div>
            <div class="price">
                <div></div><p>$18.795'000.000</p><div></div>
            </div>
        </div>
        <div class="container__icons--dimension__price" v-if="dimension==50">
            <div class="text">
                <p>El presupuesto para esta dimensión para los años 2020-2023 es de</p>
            </div>
            <div class="price">
                <div></div><p>$50.490'000.000</p><div></div>
            </div>
        </div>
        <div class="container__icons--dimension__price" v-if="dimension==51">
            <div class="text">
                <p>El presupuesto para esta dimensión para los años 2020-2023 es de</p>
            </div>
            <div class="price">
                <div></div><p>$8.999'000.000</p><div></div>
            </div>
        </div>
        <div class="container__icons--dimension__price" v-if="dimension==52">
            <div class="text">
                <p>El presupuesto para esta dimensión para los años 2020-2023 es de</p>
            </div>
            <div class="price">
                <div></div><p>$8.922'000.000</p><div></div>
            </div>
        </div>





        <div class="container__icons--dimension__title" v-if="dimension==48">
            <p>Esta dimensión se compone de 9 líneas estratégicas y el presupuesto para los años 2020-2023 es de:</p>
        </div>
        <div class="container__icons--dimension__title" v-if="dimension==49">
            <p>Esta dimensión se compone de 4 líneas estratégicas y el presupuesto para los años 2020-2023 es de:</p>
        </div>
        <div class="container__icons--dimension__title" v-if="dimension==50">
            <p>Esta dimensión se compone de 9 líneas estratégicas y el presupuesto para los años 2020-2023 es de:</p>
        </div>
        <div class="container__icons--dimension__title" v-if="dimension==51">
            <p>Esta dimensión se compone de 3 líneas estratégicas y el presupuesto para los años 2020-2023 es de:</p>
        </div>
        <div class="container__icons--dimension__title" v-if="dimension==52">
            <p>Esta dimensión se compone de 3 líneas estratégicas y el presupuesto para los años 2020-2023 es de:</p>
        </div>


        <div class="container__icons--dimension__icons" v-if="dimension==48">
            <div v-for="icon in icons" :key="icon">
                <img :src="icon.img" alt="">
            </div>
        </div>
        <div class="container__icons--dimension__icons" v-if="dimension==49">
            <div v-for="icon in icons1" :key="icon">
                <img :src="icon.img" alt="">
            </div>
        </div>
        <div class="container__icons--dimension__icons" v-if="dimension==50">
            <div v-for="icon in icons2" :key="icon">
                <img :src="icon.img" alt="">
            </div>
        </div>
        <div class="container__icons--dimension__icons" v-if="dimension==51">
            <div v-for="icon in icons3" :key="icon">
                <img :src="icon.img" alt="">
            </div>
        </div>
        <div class="container__icons--dimension__icons" v-if="dimension==52">
            <div v-for="icon in icons4" :key="icon">
                <img :src="icon.img" alt="">
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    props:{
    dimension:{
              type:Number|String
            },
    },
    data(){
        return{
            icons:[
                {img:require('../../assets/img/graficos_etiquetas/D1 - 1.png')},
                {img:require('../../assets/img/graficos_etiquetas/D1 - 2.png')},
                {img:require('../../assets/img/graficos_etiquetas/D1 - 3.png')},
                {img:require('../../assets/img/graficos_etiquetas/D1 - 4.png')},
                {img:require('../../assets/img/graficos_etiquetas/D1 - 5.png')},
                {img:require('../../assets/img/graficos_etiquetas/D1 - 6.png')},
                {img:require('../../assets/img/graficos_etiquetas/D1 - 7.png')},
                {img:require('../../assets/img/graficos_etiquetas/D1 - 8.png')},
                {img:require('../../assets/img/graficos_etiquetas/D1 - 9.png')},
            ],
            icons1:[
                {img:require('../../assets/img/graficos_etiquetas/D2 - 1.png')},
                {img:require('../../assets/img/graficos_etiquetas/D2 - 2.png')},
                {img:require('../../assets/img/graficos_etiquetas/D3 - 3.png')},
                {img:require('../../assets/img/graficos_etiquetas/D4 - 4.png')},
            ],
            icons2:[
                {img:require('../../assets/img/graficos_etiquetas/D3 - 1.png')},
                {img:require('../../assets/img/graficos_etiquetas/D3 - 2.png')},
                {img:require('../../assets/img/graficos_etiquetas/D3 - 3-1.png')},
                {img:require('../../assets/img/graficos_etiquetas/D3 - 4.png')},
                {img:require('../../assets/img/graficos_etiquetas/D3 - 5.png')},
                {img:require('../../assets/img/graficos_etiquetas/D3 - 6.png')},
                {img:require('../../assets/img/graficos_etiquetas/D3 - 7.png')},
                {img:require('../../assets/img/graficos_etiquetas/D3 - 8.png')},
                {img:require('../../assets/img/graficos_etiquetas/D3 - 9.png')},
            ],
            icons3:[
                {img:require('../../assets/img/graficos_etiquetas/D4 - 1.png')},
                {img:require('../../assets/img/graficos_etiquetas/D4 - 2.png')},
                {img:require('../../assets/img/graficos_etiquetas/D4 - 3.png')},
                
            ],
            icons4:[
                {img:require('../../assets/img/graficos_etiquetas/D5 - 1.png')},
                {img:require('../../assets/img/graficos_etiquetas/D5 - 2.png')},
                {img:require('../../assets/img/graficos_etiquetas/D5 - 3.png')},
            ]
        }
    },
    beforeMount(){
        //console.log('Los iconos :::: ',this.icons)
        //console.log('Los clientes ::: ', this.getClient().id)
    }
}
</script>
<style scoped>
.container__icons--dimension{
    width: 100%;
    background: #FFFFFF;
    
}
.container__icons--dimension__title p{
   

}
.container__icons--dimension__icons{
    width: 100%;
    /* margin: 35px; */
    /* display:grid;
    grid-template-columns: repeat(2,1fr); */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.container__icons--dimension__icons img{
    max-width: 124.25px;
    margin-bottom: 15px;
}
.container__icons--dimension__price{
    display: flex;
    justify-content: center;
    align-items:center ;
    flex-wrap: wrap;
    margin-bottom: 25px;
}
.text{
    height: 35px;
    width: 313.99px;
    background: #D8D8D8;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.text p{
    font-size: 12px;
    padding: 0 20px;
    text-align: center;
    margin: auto auto;
    
}
.price{
    display: flex;
    justify-content: center;
    align-content: center;
    height: 39px;
    width: 313.99px;
    background: #69C061;
    color: white;
    font-size: 26px;
    text-align: center;
    border-radius:  0 0 20px 20px;
    margin-top: 3px;
}
@media (min-width: 700px)  and (orientation: landscape){ 
    .container__icons--dimension__icons{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    }
    .text{
        margin: 0 50px;
    }
 }
</style>
