<template>
    <div>


        <div class="header_about_question" >
          <h1 class="header_about_question__title">
            Encuestas
          </h1>
        </div>

            <div class="container_about_question" v-if="lang == 'es'">
             <div class="about_question_box">
                <button class="about_question_button" @click="showModal(1)">
                  Desarrollo económico
                </button>
                <button class="about_question_button2"  @click="showModal(2)">
                  Fundamentos de bienestar
                </button>
             
                <button class="about_question_button3"  @click="showModal(3)">
                  Oportunidades
                </button>
             </div>   
            </div>

            <div class="container_about_question_en" v-if="lang == 'en'">
             <div class="about_question_box">
                <button class="about_question_button" @click="showModal(1)">
                  Desarrollo económico
                </button>
                <button class="about_question_button2"  @click="showModal(2)">
                  Fundamentos de bienestar
                </button>
             
                <button class="about_question_button3"  @click="showModal(3)">
                  Oportunidades
                </button>
             </div>   
            </div>


        <b-modal id="bv-modal-example" v-model="show" size="xl" title="" ok-only ok-variant="secondary" ok-title="Cerrar">
       
                <div style="width:100%" v-if="imgSelect == 1">
                    <img src="@/assets/img/infografias_dimension1_Desarrollo_economico.jpg" style="width:100%">
                </div>
                <div style="width:100%" v-if="imgSelect == 2">
                    <img src="@/assets/img/infografias_dimension2_Fundamentos_de_bienestar.jpg" style="width:100%">
                </div>
                <div style="width:100%" v-if="imgSelect == 3">
                    <img src="@/assets/img/infografias_dimension3_Oporunidades.jpg" style="width:100%">
                </div>
        </b-modal>
      
    </div>
</template>
<script>
export default {
    name: 'AboutDimension',

    data(){
        return {
            show: false,
            imgSelect:'',
            lang:'' 
        }
    },

    created(){
        this.lang = localStorage.getItem('lang')
    },

    methods:{
      goTo(){
        this.$router.push({name:'SubCategoryProduct'})
      },
      showModal(data){

        this.imgSelect = data

        this.show = true
      },
    }
};
</script>

<style lang="scss" scoped>
</style>