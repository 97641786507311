<template>
  <div>
    <div v-if="type.type == 1">
      <line-chart
        :data="chartData"
        :messages="{ empty: 'No data' }"
      ></line-chart>
    </div>
    <div v-if="type.type == 2">
      <column-chart
        :data="chartData"
        :messages="{ empty: 'No data' }"
      ></column-chart>
    </div>
    <div v-if="type.type == 3" >
      <div class="speedometer__container--gauge__title">
          <p>{{ type.name}}</p>
        </div>
      <pie-chart :data="pieData" :colors="['#1796BE', '#043C61']" :messages="{ empty: 'No data' }" :library="chartOptions"></pie-chart>
    </div>
    <div v-if="type.type == 4">
      <bar-chart :data="chartData" :messages="{ empty: 'No data' }"></bar-chart>
    </div>
    <div v-if="type.type == 5">
      <area-chart
        :data="chartData"
        :messages="{ empty: 'No data' }"
      ></area-chart>
    </div>
    <div v-if="type.type == 9">
      <scatter-chart
        :data="chartData"
        :messages="{ empty: 'No data' }"
      ></scatter-chart>
    </div>

    <div v-if="type.type == 6">
      <bar-chart :data="barsData" :messages="{ empty: 'No data' }"></bar-chart>
    </div>
    <div v-if="type.type == 7">
      <area-chart
        :data="barsData"
        :messages="{ empty: 'No data' }"
      ></area-chart>
    </div>
    <div v-if="type.type == 8">
      <scatter-chart
        :data="barsData"
        :messages="{ empty: 'No data' }"
      ></scatter-chart>
    </div>
    <div v-if="type.type == 10" class="speedometer__container">
      <div class="speedometer__container--gauge">
        <div class="speedometer__container--gauge__title">
          <p>{{ type.name}}</p>
        </div>
        <VueSvgGauge
          :start-angle="-115"
          :end-angle="115"
          :value="value"
          :separator-step="1"
          :min="0"
          :max="quantity"
          :gauge-color="[
            { offset: 0, color: 'red' },
            { offset: valueMid, color: 'yellow' },
            { offset: 100, color: 'green' },
          ]"
          :scale-interval="0.8"
        />
        <div class="speedometer__container--gauge__details">
          <div class="speedometer__container--details_high">
            <p>{{ lowValueText }}</p>
            <div class="square red"></div>
          </div>
          <div class="speedometer__container--details_high">
            <p>{{ highValueText }}</p>
            <div class="square green"></div>
          </div>
          <!-- <div class="speedometer__container--details_high">
                                                            <p>Medio</p><div class="square yellow"></div>
                                                            </div> -->
          
          <div class="speedometer__container--details_high">
            <p>{{ labelResult }}</p>
            <p>{{ result }}</p>
          </div>
        </div>

        <div class="speedometer__container--description">
          <div class="speedometer__container--description__principal">
            <p>{{ grayText }}</p>
          </div>
          <div class="speedometer__container--description__principal--price">
            <p>{{ greenText }}</p>
          </div>
          <div class="speedometer__container--description__invest">
            <p class="speedometer__container--description__invest--title">
              {{ labelOne }}:
            </p>
            <p class="space"> {{ valueOne }}</p>
          </div>
          <div class="speedometer__container--description__future--invest">
            <p class="speedometer__container--description__invest--title">
              {{ labelTwo }}:
            </p>
            <p class="space"> {{ valueTwo }}</p>
          </div>
        </div>
      </div>
    </div>
     <div v-if="type.type == 12">
      
    </div>
    <div v-if="type.type == 13">
      
    </div>

  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: Object,
    },
  },
  data() {
    return {
      value: "",
      result: "",
      valueLow: "",
      valueMid: "",
      valueHigh: "",
      nameResult: "",
      highValueText:'',
      lowValueText:'',
      labelResult:'',
      grayText:'',
      greenText:'',
      labelOne:'',
      valueOne:'',
      labelTwo:'',
      valueTwo:'',
      totalValue:'',
      quantity: "10",
      chartData: {},
      pieData: {},
      barsData: {},
      showModal: false,
      horizontalBarsData:[],
      chartOptions: {
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var total = dataset.data.reduce((previousValue, currentValue) => previousValue + currentValue);
              var currentValue = dataset.data[tooltipItem.index];
              var percentage = Math.floor(((currentValue / total) * 100) + 0.5);
              return percentage + "%";
            }
          }
        }
      }

    };
  },
  methods: {
    getPercentByKpi(){
      this.value = (this.result/this.totalValue)*10
    },
    convertJson() {
      ////console.log('entre en la funcion Json')
      const x = JSON.parse(this.type.data);
      ////console.log('aqui esta X',x)
      const info = [];
      const twoInfo = [];
      for (var i = 0; i < x.length - 1; i++) {
        info.push(x[i + 1]);
      }
      const y = x;

      for (var i = 0; i < x.length - 1; i++) {
        var r = [y[i + 1][0], y[i + 1][2]];
        twoInfo.push(r);
      }
      this.chartData = [{ name: x[0][1], data: info }];

      console.log(' pie data info', x)
      console.log(' pie datatype', this.type.type)

      if(this.type.type == 3){
        this.pieData = x
      }
      

      // this.pieData = info;
      ////console.log('datos grafica',this.chartData)
      this.barsData = [
        { name: x[0][1], data: info },
        { name: x[0][2], data: twoInfo },
      ];
      this.valueLow = info[0][1]
      this.valueMid = 50
      this.valueHigh = info[0][3]
      this.highValueText = info[0][2]
      this.lowValueText = info[0][0]
      this.labelResult = info[0][5]
      this.result = info[0][6]
      this.grayText = info[0][7],
      this.greenText = info[0][8],
      this.labelOne = info[0][9],
      this.valueOne = info[0][10],
      this.labelTwo = info[0][11],
      this.valueTwo = info[0][12]
      this.totalValue = info[0][4]
      this.getPercentByKpi()
    },
    //  goToScreen(){
    //   //console.log('Haciendo click para modal')
    //   this.updateGraph(this.type)
    //   //console.log('mirando el type',this.type)
    //   this.showModal='true'
    //   //console.log('mirando el modal',this.showModal)
    //   //this.$router.push({name:'Screen',params:{type:'grafica'}})
    // }
  },
  mounted() {
    this.renderChart(data, options);
  },
  created() {
    //console.log('mirando el type ',this.type)
    this.convertJson();
    
  },
};
</script>
<style scoped>
.speedometer__container{
  width: 55vw;
  max-width:820px;
  height: 240px;
  margin: 0 auto;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  
}
.speedometer__container--title{
  width: 100%;
}
.speedometer__container--title h2{
  text-align: left;
}
.speedometer__container--gauge{
  width: 100%;
  max-height: 240px;
  display: grid;
  grid-template-columns: 4fr 3fr 7fr;
  grid-template-rows: 1fr 3fr;
}
.speedometer__container--details_high{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.square{
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid black;
}
.green{
  background-color: rgba(0, 128, 21,0.6);
}
.red{
  background-color: rgba(255, 0, 0,0.6);
}
.yellow{
  background-color: rgba(255, 255, 0,0.6);
}
.gauge{
    margin-left: 30px;
    width: 150px ;
    height: 150px;
    grid-row: 2;
}
.speedometer__container--gauge__details{
    font-size: 10px;
    margin: 0 20px 0 0;
    grid-row: 2;
    width: auto;
    height: auto;
}
.speedometer__container--description{
    margin: 30px 0 30px 30px ;
    display:flex;
    flex-wrap: wrap;
    width: 24vw;
    max-width: 340px;
    grid-column: 3/4;
    grid-row: 1/3;
    
}


.speedometer__container--description__principal{
    display:flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 24vw;
    max-width: 340px;
    background: #D8D8D8;
    border-radius: 5px 5px 0 0;
}
.speedometer__container--description__principal p{
    text-align: center;
    padding: 0;
    margin:0
}
.speedometer__container--description__principal--price{
    margin-top: 3px;
    height: 38px;
    width: 24vw;
    max-width: 340px;
    display:flex;
    justify-content: center;
    align-items: center;
    background: #69C061;
    border-radius: 0px 0px 20px 20px;
    color:white;
}
.speedometer__container--description__principal--price p{
    text-align: center;
    padding: 0;
    margin:0;
    font-size: 22px;
    font-weight: bold;
}
.speedometer__container--description__invest{
    margin: 0 auto;
    margin-top: 24px;
    height: 34px;
    width: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F4F4F4;
    border-radius: 5px;
}
.speedometer__container--description__invest p{
   padding: 0;
    margin: 0;
}
.speedometer__container--description__future--invest{
    margin: 0 auto;
    margin-top: 6px;
    height: 34px;
    width: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F4F4F4;
    border-radius: 5px;
}
.speedometer__container--description__future--invest p{
    padding: 0;
    margin: 0;
}
.speedometer__container--gauge__title{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-column: 1/3;
    grid-row: 1;
}
.speedometer__container--gauge__title p{
    
    font-size: 14px;
    font-weight: bold;
    grid-column: 1/3;
    grid-row: 1;
    text-align:center;
    padding:8px;
}
.speedometer__container--description__invest--title{
    font-weight: bold;
}
.space{
  margin-left: 5px !important;
}

@media (max-width: 900px) {
  .speedometer__container{
    width: auto;
    height: auto;
  }
  .speedometer__container--gauge{
    width: auto;
    height: auto;
    grid-template-columns: 2fr 3fr;
  }
  .speedometer__container--gauge__title{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-column: 1/3;
    grid-row: 1;
  }
  .gauge{
      margin-left: 30px;
      width: 150px ;
      height: 150px;  
      grid-column: 1/2;
      grid-row: 2;

  }
  .speedometer__container--gauge__details{
      font-size: 10px;
      margin-top: 25px;
      margin-left: 15px;;
      width: auto;
      height: auto;
      grid-column: 2/3;
      grid-row: 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
  }
  .speedometer__container--details_high p{
    font-size: 10px;
    padding-right: 20px;
  }
  .speedometer__container--description{
      margin: 0;
      padding: 0;
      display:flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      max-width: 340px;   
      grid-column: 1/3;
      grid-row: 3;   
  }
  .speedometer__container--description__principal{
      width: 270px 
  }
  .speedometer__container--description__principal--price{
      width: 270px 
  }
}
</style>