<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="57.254" viewBox="0 0 60 57.254">
    
    <g transform="translate(0 -0.467)">
        <path :fill="color" class="a" d="M29.8,224.721a15.345,15.345,0,0,1-6.114-1.263l-9.251-4.021A35.08,35.08,0,0,0,3.918,216.7l-3.038-.3A.977.977,0,0,1,0,215.419V205.89a.976.976,0,0,1,.2-.586c.227-.3,5.672-7.417,13.989-5.566a35.32,35.32,0,0,1,10.78,4.434,10.884,10.884,0,0,0,5.748,1.607h9.251a4.443,4.443,0,0,1,3.78,2.109l7.967-9.179a8.451,8.451,0,0,1,6.717-3.326h.6a.978.978,0,0,1,.9,1.371L52.712,213.13a12.838,12.838,0,0,1-8.58,7.237l-1.268.317-7.375,2.95A15.34,15.34,0,0,1,29.8,224.721ZM1.956,214.534l2.157.216a37.052,37.052,0,0,1,11.1,2.893l9.25,4.021a13.452,13.452,0,0,0,10.3.152l7.435-2.974a1.008,1.008,0,0,1,.126-.04l1.332-.333a10.868,10.868,0,0,0,7.265-6.127l6.572-14.937a6.536,6.536,0,0,0-4.263,2.53L44.4,210.108c0,.038,0,.076,0,.114a4.448,4.448,0,0,1-4.443,4.443H27.057a15.561,15.561,0,0,0-4.928.8l-.359-.912-.3-.929.05-.016a17.5,17.5,0,0,1,5.541-.9H39.963a2.488,2.488,0,1,0,0-4.976H30.713a12.838,12.838,0,0,1-6.779-1.9,33.362,33.362,0,0,0-10.173-4.186c-6.3-1.4-10.83,3.437-11.805,4.59v8.3Zm0,0" transform="translate(0 -167)" />
        <path :fill="color" class="a" d="M232.129,106.79a11.477,11.477,0,0,1-5.046-1.392.983.983,0,0,1-.483-.545c-.076-.212-1.823-5.238.954-8.954,2.018-2.7,5.812-3.918,11.284-3.614a.976.976,0,0,1,.921.928c.02.388.423,9.553-4.25,12.6A6.044,6.044,0,0,1,232.129,106.79Zm-3.8-2.962c2.568,1.2,4.622,1.323,6.11.351,2.771-1.81,3.37-7.182,3.385-9.978-4.287-.1-7.206.862-8.7,2.867C227.336,99.456,228.029,102.741,228.33,103.828Zm0,0" transform="translate(-193.622 -78.628)" />
        <path :fill="color" class="a" d="M107.276,15.351A9.149,9.149,0,0,1,101.8,13.7c-2.961-2.17-4.321-6.3-4.045-12.276a.978.978,0,0,1,.919-.931c.423-.024,10.434-.558,13.8,4.5,1.569,2.354,1.44,5.436-.381,9.164a.976.976,0,0,1-.541.488A13.5,13.5,0,0,1,107.276,15.351Zm3.938-1.624h.01ZM99.676,2.418c-.084,4.787,1.016,8.049,3.276,9.705,2.706,1.982,6.4,1.14,7.561.8,1.329-2.883,1.441-5.186.333-6.848C108.787,2.986,102.75,2.382,99.676,2.418Zm0,0" transform="translate(-83.721 0)" />
        <path :fill="color" class="a" d="M150.39,77.82c-.031,0-.062,0-.093,0a.978.978,0,0,1-.884-1.023q.086-1.725.29-3.275c.407-6.919.2-23.033-9.991-27.49a.978.978,0,1,1,.784-1.791c6,2.627,9.78,9.166,10.92,18.911.1.873.179,1.734.236,2.573,2.683-6.78,6.788-9.176,7.032-9.313a.978.978,0,0,1,.961,1.7c-.1.06-6.4,3.838-7.978,15.487-.12,1.993-.294,3.263-.31,3.38A.977.977,0,0,1,150.39,77.82Zm0,0" transform="translate(-119.2 -37.431)" />
    </g>
    </svg>
</template>
<script>
export default {
    props:{
        color: {
            default: '#24A638',
            type: String
        }
    }
}
</script>