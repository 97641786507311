<template>
    <dir class="container__bannerdynamic">
        <div class="container__bannerdynamic--img">
            <img :src="program.imagen ? program.imagen.url : '#'" alt="">
        </div>
        <div class="container__bannerdynamic--titles">
            <h2 :style="{'color':getClient().primary_color}">{{program.name}}</h2>
            <p :style="{'color':getClient().primary_color}">{{program.subtitle}}</p>
        </div>
        <div class="container__bannerdynamic--button">
            <button :style="{'background':getClient().primary_color}" @click="goToProgram(program.id)">Conoce más</button>
        </div>
    </dir>
</template>
<script>
export default {
    data(){
        return{
            program:[]
        }
    },
    methods:{
        getProgram(){
            this.$http.get("programs/townhall/"+this.getClient().id)
            .then((response)=>{
                this.program = response.data
                console.log('aqui esta el ',this.program)
            })
        },
        goToProgram(id){
            this.$router.push({name:'Develop',params:{id:id}})
        }
    },
    beforeMount(){
        this.getProgram()
    }
}
</script>
<style scoped>
.container__bannerdynamic{
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    background: white;
    margin-top: 30px;
    margin-bottom: 30px;
}
.container__bannerdynamic--img img{
    width: 274px;
    height: 90px;
}
.container__bannerdynamic--titles{
    width: 100%;
    height: 90px;
    padding-left: 25px;
}
.container__bannerdynamic--titles h2{
    padding-top: 17px;
 font-size: 22px;
}
.container__bannerdynamic--titles p{
  font-size: 16px;
}
.container__bannerdynamic--button{
    width: 250px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container__bannerdynamic--button button{
    height: 40px;
    width: 200px;
    color: white;
    border-radius: 23px;
    border:none;
    text-decoration: none;
    outline:none;
    margin-right: 40px;
}
.container__bannerdynamic--button button:hover{
    background-color: rgba(0,0,0,0.4);
}
@media (max-width: 995px)  { 
    .container__bannerdynamic{
        display: block;
        height: 98px;
        flex-wrap: wrap;
    }
   .container__bannerdynamic--img{
    display:none;
    }
    .container__bannerdynamic--button{
        width: 100%;
        height: auto;
    }
    .container__bannerdynamic--button button{
         padding-top: 9px;
        margin: 0;
    }
    .container__bannerdynamic--titles{
        height: auto;
        text-align: center;
    }
    .container__bannerdynamic--titles h2{
        margin: 0;
        padding-top: 9px;
        font-size: 16px;
    }
    .container__bannerdynamic--titles p{
        margin: 0;
        font-size: 13px;
    }
}
</style>