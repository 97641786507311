<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="41.172" height="60" viewBox="0 0 41.172 60">
        <g transform="translate(-80.333)">
            <g transform="translate(80.333)">
            <path :fill="color" class="a" d="M120.652,2.425A16.755,16.755,0,0,0,111.789,0c-3.881,0-7.692,1.143-11.376,2.248-3.6,1.08-7,2.1-10.365,2.1h0a13.354,13.354,0,0,1-6.2-1.451V1.758a1.758,1.758,0,1,0-3.516,0V58.242a1.758,1.758,0,0,0,3.516,0v-27.6a16.983,16.983,0,0,0,6.2,1.119c3.881,0,7.691-1.143,11.376-2.248,3.6-1.08,7-2.1,10.365-2.1a13.332,13.332,0,0,1,7.053,1.924,1.758,1.758,0,0,0,2.662-1.507V3.932A1.757,1.757,0,0,0,120.652,2.425ZM100.414,26.142c-3.6,1.08-7,2.1-10.365,2.1a13.357,13.357,0,0,1-6.2-1.45V6.746a16.976,16.976,0,0,0,6.2,1.118h0c3.881,0,7.691-1.143,11.375-2.248,3.6-1.08,7-2.1,10.366-2.1a13.358,13.358,0,0,1,6.2,1.45V25.012a16.982,16.982,0,0,0-6.2-1.118C107.908,23.894,104.1,25.037,100.414,26.142Z" transform="translate(-80.333 0)" />
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    props:{
        color: {
            default: '#69C061',
            type: String
        }
    }
}
</script>