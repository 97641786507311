const state = {
    client: []
}
  
const getters = {
    getClient(state){
        return state.client;
    }
}
  
const mutations = {
    setClient(state, payload){
        state.client = payload;
    },
    setDeleteClient(state){
        state.client.splice(0,state.client.length)
    }
}
  
const actions = {
    updateClient ({commit}, payload) {
        commit('setClient', payload)
    },
    
}
  
  export default {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
  }