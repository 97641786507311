<template>
    <div>

        <div class="header_about_indicator" >
          <h1 class="header_about_indicator__title">
            Tu Territorio
          </h1>
        </div>

        <a  @click="goTo()" >
            <div class="container_about_indicator" v-if="lang == 'es'">
                <button class="about_indicator_button" @click="goTo()">
                Más información
                </button>
            </div>

            <div class="container_about_indicator_en" v-if="lang == 'en'">
                <button class="about_indicator_button" @click="goTo()">
                Más información
                </button>
            </div>

        </a>
    </div>        
</template>
<script>
export default {

    name: 'AboutIndicator',

    data(){
        return {
            lang:''
        }
    },

    created(){
        this.lang = localStorage.getItem('lang')
    },

    methods:{
      goTo(){
        this.$router.push({name:'Indicators'})
      }
    }

};
</script>

<style lang="scss" scoped>
</style>