<template>
    <div class="container_dimensions">
        <div id="overlay"></div>
        <div class="container_dimensions__title" :style="{color: color_title}">
          <p :style="{color: color_title}">
            DIMENSIONES TERRITORIALES
          </p>
        </div>


                <div class="development_plan_container" >
          <div class="development_plan_container_row" >
            <div class="development_plan_container__dimension" v-for="(Dimension, keyDimension) in dimensions" :key="keyDimension">
            
             
                <div v-if="keyDimension % 2 == 0 && id_dimension == keyDimension && screenWidth > '575.98' " class="development_plan_container__dimension--img" :style="{'z-index':z_index, 'position':position, 'background-image': `url(${Dimension.img_dimension.url})`, 'background-size': 'cover', 'background-position': 'center'}" @click="getDimension(Dimension.id)">
                </div>

                <div v-else-if="keyDimension % 2 == 0 && screenWidth > '575.98' " class="development_plan_container__dimension--img" :style="{'background-image': `url(${Dimension.img_dimension.url})`, 'background-size': 'cover', 'background-position': 'center'}" @click="getDimension(Dimension.id)">
                </div>



                 <div v-if="id_dimension == keyDimension && screenWidth < '575.98'" class="development_plan_container__dimension--img2" :style="{'z-index':z_index, 'position':position, 'background-image': `url(${Dimension.img_dimension.url})`, 'background-size': 'cover', 'background-position': 'center'}" @click="getDimension(Dimension.id)">
                </div>

                <div v-else-if=" screenWidth < '575.98'" class="development_plan_container__dimension--img2" :style="{'background-image': `url(${Dimension.img_dimension.url})`, 'background-size': 'cover', 'background-position': 'center'}" @click="getDimension(Dimension.id)">
                </div>
              
           


            <div class="development_plan_container__dimension--box" v-if="id_dimension == keyDimension" :style="{'z-index':z_index, 'position':position}">
              <div class="development_plan_container__dimension--text" :style="{'background-color': Dimension.color_dimension}" @click="getDimension(Dimension.id)">
                <p>
                  {{Dimension.name_dimension}} 
                </p>
                <!-- <img src="@/assets/img/arrow-up.svg" class="development_plan_container__dimension--arrow"> -->
              </div>
              <div class="development_plan_container__block">
                <div class="development_plan_container__dimension--segments">
                <div class="container_development_plan_segments" :class="{'expanded-block': Dimension.open}">
                  <div class="container_development_plan_segments__segment" v-for="(Segment, keySegment) in Dimension.segments" :key="keySegment">
                    <!-- {{Segment}} -->
                    <div  class="container_development_plan_segments__segment--link" @click="getSegment(Dimension.id,Segment)" v-b-toggle="'accordion-'+Segment.id" :style="{'background-color': accordions[accordions.findIndex(item => item.name === 'accordion-'+Segment.id)].toggle ? '#F4F4F4' : '', 'color': accordions[accordions.findIndex(item => item.name === 'accordion-'+Segment.id)].toggle ? '#383838' : ''}">
                      <div class="container_development_plan_segments__segment--arrow">
                        <img src="@/assets/img/arrow-close.svg" v-if="!accordions[accordions.findIndex(item => item.name === 'accordion-'+Segment.id)].toggle">
                        <img src="@/assets/img/arrow-close.svg" v-else style="transform: rotate(90deg)">
                      </div>
                      <div class="container_development_plan_segments__segment--title" >
                        {{Segment.name_architecture}}
                      </div>
                    </div>

                    <b-collapse :id="'accordion-'+Segment.id" :accordion="'my-accordion-'+Dimension.id" class="container_development_plan_items">
                      <div class="container_development_plan_items__list" v-for="(Category, keyCategory) in Segment.categorias" :key="keyCategory">
                        <div class="container_development_plan_items__list--item" @click="getCategory(Dimension.id, Category.id)">
                          <div class="container_development_plan_items__list--check">
                            <img src="@/assets/img/check-normal.svg" alt="">
                          </div>
                          <div class="container_development_plan_items__list--title">
                            {{Category.tag}} 
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </div>
   
              <button 
                type="button" 
                style=""
                v-if="Dimension.segments.length > 2" 
                class="button_expand fixed_button button_dimension"
                @click="Dimension.open = !Dimension.open, overlay(Dimension.open, keyDimension)"
              >
                <span v-if="!Dimension.open">Ver más</span>
                <span v-if="Dimension.open">Ver menos</span>
                <span class="button_expand__icon">
                  <ArrowDownIcon color="#FFFFFF" v-if="!Dimension.open" />
                  <ArrowUpIcon color="#FFFFFF" v-if="Dimension.open" />
                </span>
              </button>
              </div>
          </div>  


          
            <div class="development_plan_container__dimension--box" v-else>
              <div class="development_plan_container__dimension--text2" :style="{'background-color': Dimension.color_dimension}" @click="getDimension(Dimension.id)">
                <p>
                  {{Dimension.name_dimension}} 
                </p>
                <!-- <img src="@/assets/img/arrow-up.svg" class="development_plan_container__dimension--arrow"> -->
              </div>
              <div class="development_plan_container__block">
                <div class="development_plan_container__dimension--segments">
                <div class="container_development_plan_segments" :class="{'expanded-block': Dimension.open}">
                  <div class="container_development_plan_segments__segment" v-for="(Segment, keySegment) in Dimension.segments" :key="keySegment">
                    <!-- {{Segment}} -->
                    <div  class="container_development_plan_segments__segment--link" @click="getSegment(Dimension.id,Segment)" v-b-toggle="'accordion-'+Segment.id" :style="{'background-color': accordions[accordions.findIndex(item => item.name === 'accordion-'+Segment.id)].toggle ? '#F4F4F4' : '', 'color': accordions[accordions.findIndex(item => item.name === 'accordion-'+Segment.id)].toggle ? '#383838' : ''}">
                      <div class="container_development_plan_segments__segment--arrow">
                        <img src="@/assets/img/arrow-close.svg" v-if="!accordions[accordions.findIndex(item => item.name === 'accordion-'+Segment.id)].toggle">
                        <img src="@/assets/img/arrow-close.svg" v-else style="transform: rotate(90deg)">
                      </div>
                      <div class="container_development_plan_segments__segment--title" >
                        {{Segment.name_architecture}}
                      </div>
                    </div>

                    <b-collapse :id="'accordion-'+Segment.id" :accordion="'my-accordion-'+Dimension.id" class="container_development_plan_items">
                      <div class="container_development_plan_items__list" v-for="(Category, keyCategory) in Segment.categorias" :key="keyCategory">
                        <div class="container_development_plan_items__list--item" @click="getCategory(Dimension.id, Category.id)">
                          <div class="container_development_plan_items__list--check">
                            <img src="@/assets/img/check-normal.svg" alt="">
                          </div>
                          <div class="container_development_plan_items__list--title">
                            {{Category.tag}} 
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </div>
   
              <button 
                type="button" 
                style=""
                v-if="Dimension.segments.length > 2" 
                class="button_expand fixed_button button_dimension"
                @click="Dimension.open = !Dimension.open, overlay(Dimension.open, keyDimension)"
              >
                <span v-if="!Dimension.open">Ver más</span>
                <span v-if="Dimension.open">Ver menos</span>
                <span class="button_expand__icon">
                  <ArrowDownIcon color="#FFFFFF" v-if="!Dimension.open" />
                  <ArrowUpIcon color="#FFFFFF" v-if="Dimension.open" />
                </span>
              </button>
              </div>
          </div>


           
              <div v-if="keyDimension % 2 != 0 && id_dimension == keyDimension && screenWidth > '575.98'" class="development_plan_container__dimension--img2" :style="{'z-index':z_index, 'position':position, 'background-image': `url(${Dimension.img_dimension.url})`, 'background-size': 'cover', 'background-position': 'center'}" @click="getDimension(Dimension.id)">
              </div>

              <div v-else-if="keyDimension % 2 != 0 && screenWidth > '575.98'" class="development_plan_container__dimension--img2" :style="{'background-image': `url(${Dimension.img_dimension.url})`, 'background-size': 'cover', 'background-position': 'center'}" @click="getDimension(Dimension.id)">
              </div>
<!--   
              <div v-if="id_dimension == keyDimension && screenWidth < '575.98'" class="development_plan_container__dimension--img2" :style="{'z-index':z_index, 'position':position, 'background-image': `url(${Dimension.img_dimension.url})`, 'background-size': 'cover', 'background-position': 'center'}" @click="getDimension(Dimension.id)">
              </div>

              <div v-else-if="screenWidth < '575.98'" class="development_plan_container__dimension--img2" :style="{'background-image': `url(${Dimension.img_dimension.url})`, 'background-size': 'cover', 'background-position': 'center'}" @click="getDimension(Dimension.id)">
              </div> -->
              
              
            </div>
          </div>



          <div v-if="dimensions.length % 2" style="width: 32%">
          </div>
        </div>
        
        <!-- <div class="dimensions_container">
          <div class="dimension_container_row" v-if="dimension_row_1.length > 0">
            <div class="dimensions_container__dimension" v-for="(Dimension, keyDimension) in dimension_row_1" :key="keyDimension">
              <div class="dimensions_container__dimension--img" :style="{'background-image': `url(${Dimension.img_dimension.url})`, 'background-size': 'cover', 'background-position': 'center'}" @click="getDimension(Dimension.id)">
              </div>
              <div class="dimensions_container__dimension--text" :style="{'background-color': Dimension.color_dimension}" @click="getDimension(Dimension.id)">
                <p>
                  {{Dimension.name_dimension}} 
                </p>
                <img src="@/assets/img/arrow-up.svg" class="dimensions_container__dimension--arrow">
              </div>
              <div class="dimension_container__block">
                <div class="dimensions_container__dimension--segments">
                <div class="container_segments" :class="{'expanded-block': Dimension.open}">
                  <div class="container_segments__segment" v-for="(Segment, keySegment) in Dimension.segments" :key="keySegment">
                    
                    <div class="container_segments__segment--link" v-b-toggle="'accordion-'+Segment.id" :style="{'background-color': accordions[accordions.findIndex(item => item.name === 'accordion-'+Segment.id)].toggle ? '#F4F4F4' : '', 'color': accordions[accordions.findIndex(item => item.name === 'accordion-'+Segment.id)].toggle ? '#383838' : ''}">
                      <div class="container_segments__segment--arrow">
                        <img src="@/assets/img/arrow-close.svg" v-if="!accordions[accordions.findIndex(item => item.name === 'accordion-'+Segment.id)].toggle">
                        <img src="@/assets/img/arrow-close.svg" v-else style="transform: rotate(90deg)">
                      </div>
                      <div class="container_segments__segment--title" >
                        {{Segment.name_architecture}}
                      </div>
                    </div>
                    <b-collapse :id="'accordion-'+Segment.id" :accordion="'my-accordion-'+Dimension.id" class="container_items">
                      <div class="container_items__list" v-for="(Category, keyCategory) in Segment.categorias" :key="keyCategory">
                        <div class="container_items__list--item" @click="getCategory(Dimension.id, Category.id)">
                          <div class="container_items__list--check">
                            <img src="@/assets/img/check-normal.svg" alt="">
                          </div>
                          <div class="container_items__list--title">
                            {{Category.tag}}
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </div>
              <button 
                type="button" 
                v-if="Dimension.segments.length > 2" 
                class="button_expand fixed_button"
                @click="Dimension.open = !Dimension.open"
              >
                <span v-if="!Dimension.open">Expandir</span>
                <span v-if="Dimension.open">Reducir</span>
                <span class="button_expand__icon">
                  <ArrowDownIcon color="#FFFFFF" v-if="!Dimension.open" />
                  <ArrowUpIcon color="#FFFFFF" v-if="Dimension.open" />
                </span>
              </button>
              </div>
            </div>
          </div>
          <div class="dimension_container_row" v-if="dimension_row_2.length > 0">
            <div class="dimensions_container__dimension" v-for="(Dimension, keyDimension) in dimension_row_2" :key="keyDimension">
              <div class="dimensions_container__dimension--img" :style="{'background-image': `url(${Dimension.img_dimension.url})`, 'background-size': 'cover', 'background-position': 'center'}" @click="getDimension(Dimension.id)">
              </div>
              <div class="dimensions_container__dimension--text" :style="{'background-color': Dimension.color_dimension}" @click="getDimension(Dimension.id)">
                <p>
                  {{Dimension.name_dimension}}
                </p>
                <img src="@/assets/img/arrow-up.svg" class="dimensions_container__dimension--arrow">
              </div>
              <div class="dimension_container__block">
                <div class="dimensions_container__dimension--segments">
                <div class="container_segments" :class="{'expanded-block': Dimension.open}">
                  <div class="container_segments__segment" v-for="(Segment, keySegment) in Dimension.segments" :key="keySegment">
                    
                    <div class="container_segments__segment--link" v-b-toggle="'accordion-'+Segment.id" :style="{'background-color': accordions[accordions.findIndex(item => item.name === 'accordion-'+Segment.id)].toggle ? '#F4F4F4' : '', 'color': accordions[accordions.findIndex(item => item.name === 'accordion-'+Segment.id)].toggle ? '#383838' : ''}">
                      <div class="container_segments__segment--arrow">
                        <img src="@/assets/img/arrow-close.svg" v-if="!accordions[accordions.findIndex(item => item.name === 'accordion-'+Segment.id)].toggle">
                        <img src="@/assets/img/arrow-close.svg" v-else style="transform: rotate(90deg)">
                      </div>
                      <div class="container_segments__segment--title" >
                        {{Segment.name_architecture}}
                      </div>
                    </div>
                    <b-collapse :id="'accordion-'+Segment.id" :accordion="'my-accordion-'+Dimension.id" class="container_items">
                      <div class="container_items__list" v-for="(Category, keyCategory) in Segment.categorias" :key="keyCategory">
                        <div class="container_items__list--item" @click="getCategory(Dimension.id, Category.id)">
                          <div class="container_items__list--check">
                            <img src="@/assets/img/check-normal.svg" alt="">
                          </div>
                          <div class="container_items__list--title">
                            {{Category.tag}}
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </div>
              <button 
                type="button" 
                v-if="Dimension.segments.length > 2" 
                class="button_expand fixed_button"
                @click="Dimension.open = !Dimension.open"
              >
                <span v-if="!Dimension.open">Expandir</span>
                <span v-if="Dimension.open">Reducir</span>
                <span class="button_expand__icon">
                  <ArrowDownIcon color="#FFFFFF" v-if="!Dimension.open" />
                  <ArrowUpIcon color="#FFFFFF" v-if="Dimension.open" />
                </span>
              </button>
              </div>
            </div>
          </div>
          <div class="dimension_container_row" v-if="dimension_row_3.length > 0">
            <div class="dimensions_container__dimension" v-for="(Dimension, keyDimension) in dimension_row_3" :key="keyDimension">
              <div class="dimensions_container__dimension--img" :style="{'background-image': `url(${Dimension.img_dimension.url})`, 'background-size': 'cover', 'background-position': 'center'}" @click="getDimension(Dimension.id)">
              </div>
              <div class="dimensions_container__dimension--text" :style="{'background-color': Dimension.color_dimension}" @click="getDimension(Dimension.id)">
                <p>
                  {{Dimension.name_dimension}} 
                </p>
                <img src="@/assets/img/arrow-up.svg" class="dimensions_container__dimension--arrow">
              </div>
              <div class="dimension_container__block">
                <div class="dimensions_container__dimension--segments">
                <div class="container_segments" :class="{'expanded-block': Dimension.open}">
                  <div class="container_segments__segment" v-for="(Segment, keySegment) in Dimension.segments" :key="keySegment">
                    
                    <div class="container_segments__segment--link" v-b-toggle="'accordion-'+Segment.id" :style="{'background-color': accordions[accordions.findIndex(item => item.name === 'accordion-'+Segment.id)].toggle ? '#F4F4F4' : '', 'color': accordions[accordions.findIndex(item => item.name === 'accordion-'+Segment.id)].toggle ? '#383838' : ''}">
                      <div class="container_segments__segment--arrow">
                        <img src="@/assets/img/arrow-close.svg" v-if="!accordions[accordions.findIndex(item => item.name === 'accordion-'+Segment.id)].toggle">
                        <img src="@/assets/img/arrow-close.svg" v-else style="transform: rotate(90deg)">
                      </div>
                      <div class="container_segments__segment--title" >
                        {{Segment.name_architecture}}
                      </div>
                    </div>
                    <b-collapse :id="'accordion-'+Segment.id" :accordion="'my-accordion-'+Dimension.id" class="container_items">
                      <div class="container_items__list" v-for="(Category, keyCategory) in Segment.categorias" :key="keyCategory">
                        <div class="container_items__list--item" @click="getCategory(Dimension.id, Category.id)">
                          <div class="container_items__list--check">
                            <img src="@/assets/img/check-normal.svg" alt="">
                          </div>
                          <div class="container_items__list--title">
                            {{Category.tag}}
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </div>
              <button 
                type="button" 
                v-if="Dimension.segments.length > 2" 
                class="button_expand fixed_button"
                @click="Dimension.open = !Dimension.open"
              >
                <span v-if="!Dimension.open">Expandir</span>
                <span v-if="Dimension.open">Reducir</span>
                <span class="button_expand__icon">
                  <ArrowDownIcon color="#FFFFFF" v-if="!Dimension.open" />
                  <ArrowUpIcon color="#FFFFFF" v-if="Dimension.open" />
                </span>
              </button>
              </div>
            </div>
          </div>
          <div v-if="dimensions.length % 2" style="width: 32%">
          </div>
        </div> -->
      </div>
</template>
<script>
export default {
  props: {
    color_title: {
      type: String,
      default: '#707070'
    }
  },
  data() {
    return {
      observatorio:this.getClient(),
      dimensions:[],
      dimension_row_1: [],
      dimension_row_2: [],
      dimension_row_3: [],
      accordions:[],
      categories:[],
      other:[],
      segmento:[],
      expanded: false,
      order: '',
      z_index:0,
      position: '',
      id_dimension: '',
      screenWidth: window.innerWidth
    }
  },
  methods: {

    overlay(data,keyDimension){

      console.log('overlay', data)

      if( data == true){

        document.getElementById("overlay").style.display = "block";

        this.z_index = 1;
        this.position = 'relative'
        this.id_dimension = keyDimension

      }else{

        document.getElementById("overlay").style.display = "none";

        this.z_index = 0;
        this.position = ''
        this.id_dimension = ''

      }


    },


    async fullCategories(){
      //console.log('ingresando a full')
      try{
          const fullCat = await this.$http.get('architecture_tags/dimension/'+this.id)
          this.categories=fullCat.data
      }catch(error){
          //console.log(error)
      }
    },
    getDimension(id){
        this.$router.push({name: 'Dimension', params: {id: id}});
    },



    getSegment(Dimension,Segment){
      console.log('segment', Segment)

      this.$router.push({name: 'Dimension', params: {id: Dimension}, query: {Category: Segment.id}});

    },

    getCategory(Dimension, Category){
      console.log('category', category)
      this.$router.push({name: 'Dimension', params: {id: Dimension}, hash: `#Category-${Category}`,query: {Category: Category} });
    },
    getDimensionInfo(){
      console.log()
      this.dimensions = [];
      this.$http.get('dimensions/townhall/'+this.getClient().id).then(response => {
        response.data.forEach(element => {

          element.segments.forEach(element2 => {
            this.accordions.push({
              name: 'accordion-'+element2.id,
              toggle: false
            })
          });
          
        });

        this.dimensions = response.body.map(item => ({
          //architecture: item.architecture,
          color_dimension: item.color_dimension,
          id: item.id,
          img_dimension: item.img_dimension,
          name_dimension: item.name_dimension,
          segments: item.segments,
          //text_dimension: item.text_dimension,
          townhall_id: item.townhall_id,
          open: false,
          subcategory: item.subcategory
        }));

        this.dimensions = this.dimensions.filter(item => item.subcategory == '2');

        console.log('this.dimensions', this.dimensions)

        const increment = screen.width <= 991.98 ? 1 : 3;

        // for(var i = 0; i < this.dimensions.length; i+=increment){
        //   this.dimension_row_1.push(this.dimensions[i]);
        // }

        // if(increment == 3) {
        //   for(var i = 1; i < this.dimensions.length; i+=increment){
        //     this.dimension_row_2.push(this.dimensions[i]);
        //   }

        //   for(var i = 2; i < this.dimensions.length; i+=increment){
        //     this.dimension_row_3.push(this.dimensions[i]);
        //   }
        // }

    
        console.log('Dimensión mod:',this.dimension_row_1)
      }).catch(error => {
        //console.log('ERROR AL CONSULTAR LAS DIMENSIONES', error)
      })
    },


      onResize() {
      this.screenWidth = window.innerWidth
    }

  },
  created() {
    this.getDimensionInfo();
    /*window.addEventListener("resize", function(event) {
      //console.log(document.body.clientWidth + ' wide by ' + document.body.clientHeight+' high');
    })*/
    console.log('screenWidth', this.screenWidth)
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      
      this.accordions.forEach((element, index) => {
        if (element.name == collapseId) {
          this.accordions[index].toggle = isJustShown
        }
      });
    });

     this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })

  },

  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
}
</script>

<style scoped>
#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 0; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
</style>