<template>
  <div class="container_products">
    <div class="button__next--step" @click="otherProduct('left')">
      <div v-if="leftShow==false">

      </div>
    </div>
    <div class="container_products--container__align">
      <div class="container_products--container__align">
        <div class="container_products--container">
          <div class="container_products--container__title" :style="{'background-color':color }">
            <p>{{ segmentName }}</p>
          </div>
          <div class="container_products--container__metric">
            <div  class="container_metric" style="width: 600px;  padding: 20px ">
                <div>
                    <h6>{{product[2]}}</h6>
                    <bar-chart :data="horizontalBarsData" :library="chartOptions" :colors="[colors]" :messages="{empty: 'No data'}"></bar-chart>
                </div>
                <h6 class="container_metric--title">Periodicidad: {{product[1]}}</h6>
                <p  class="container_metric--description">Fuente: Dirección de Desarrollo Rural - Observatorio El Retiro</p>
                
            </div>
            <div class="container_products--geographical" :style="{'background-color':color }">
                {{product[3]}}
            </div>
          </div>
          <div
            class="container_products--container__boton"
            @click="getOutModal"
          >
            <img
              :src="
                require('../../assets/img/icons-modal-products/icono-cerrar.svg')
              "
              alt=""
            />
            <p>CERRAR</p>
          </div>
        </div>
      </div>
    </div>
    <div class="button__next--step" @click="otherProduct('right')">
      <div v-if="rightShow==false">
 
      </div>
    </div>
  </div>
</template>
<script>
export default {
 
  props:['product','color','segmentName'],
  data() {
    return {
      findId:0,
      leftShow:false,
      rightShow:true,
      horizontalBarsData:'',
      colors:[],
      chartOptions: {
        layout: {
          padding: {left: 10, right: 5, top: 5, bottom: 2},
        },
        legend: { position: "left", labels: { fontSize: 26 } },
        scales: {
          xAxes: [{
            display: false, // this hides the x-axis
            gridLines: {
              display: false,
               
            },
          }],
          yAxes: [{
            
            gridLines: {
              display: false,
              color: '#000000',
              // drawTicks: true,
              drawBorder: false, // <---
              lineWidth: 5,
              zeroLineWidth: 1             
            },
            ticks: {
                beginAtZero: true,
                display: true
            }

          }]
        }
      }
    };
  },
  methods: {

    convertDataGraph(product){

        console.log('los product', product)

      // this.horizontalBarsData=[ ['META 2020',product[4]], ['EJECUTADO 2020',product[5]], ['META 2021',product[6]], ['EJECUTADO 2021',product[7]] ]
      this.horizontalBarsData=[ ['2018',product[5]], ['2019',product[6]], ['2020',product[7]], ['2021',product[8]] ,['2022',product[9]],['2023',product[10]] ]

      console.log('horizontalBarsData', this.horizontalBarsData)
        
    },

    generateColors(color, percent){

     var R = parseInt(color.substring(1,3),16); 
     var G = parseInt(color.substring(3,5),16); 
     var B = parseInt(color.substring(5,7),16); 
     
     R = parseInt(R * (100 + percent) / 100); 
     G = parseInt(G * (100 + percent) / 100); 
     B = parseInt(B * (100 + percent) / 100); 
     
     R = (R<255)?R:255; 
     G = (G<255)?G:255; 
     B = (B<255)?B:255; 
     
     var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16)); 
     var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16)); 
     var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16)); 
     
     var newColor = "#"+RR+GG+BB

     this.colors.push(newColor)

    console.log('this.colors',this.colors)
    },


    getOutModal() {
      this.$emit("cancelProduct", false);
      //console.log("Respuesta ::: ", this.showModal);
    },
    searchIndex(value){
      if (this.modalCategory[this.findId].id==value){
        return this.findId
      }else{
        this.findId=this.findId+1
        this.searchIndex(value)
      }
      this.showSignals()
    },
    otherProduct(value){
      if (value=="right"){
        if(this.findId<this.modalCategory.length-1){
          this.findId=this.findId+1
          //console.log("probando el other",this.findId)
          this.showSignals()
          this.$emit("otherProduct", this.modalCategory[this.findId],this.modalCategory);
        }
      }
      if(value=="left"){
        if(this.findId>0){
          this.findId=this.findId-1
          //console.log("probando el other",this.findId)
          this.showSignals()
          this.$emit("otherProduct", this.modalCategory[this.findId],this.modalCategory);
        }
        
      }
    },
    //this function is for showing arrow's left a  or arrow's right
    showSignals(){
      if (this.findId != 0){
        this.leftShow=true
      }else{
        this.leftShow=false
      }
      if (this.findId == this.modalCategory.length-1){
        this.rightShow=false
      }else{
        this.rightShow=true
      }
      const obj ={
        index:this.findId,
        derecha:this.rightShow,
        izquierda:this.leftShow,
        tamaño:this.modalCategory.length-1
      }
      //console.log('probando',obj)
    }

  },
  beforeMount() {
    //console.log(this.product,this.modalCategory);
    //console.log("los pinches id",this.modalCategory[0].id)
    //console.log("los pinches id",this.modalCategory[this.modalCategory.length-1].id)
    // this.searchIndex(this.product.id)
    //console.log('aqui fue',this.findId)
   
    this.convertDataGraph(this.product)

    for(var i=1; i<=4; i++){
        var percent = 20 * i
        this.generateColors(this.color,percent)
    }
  },
};
</script>

<style scoped lang="scss">
.container_products {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 12;
  background: #00000080;
  top: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.button__next--step {
  width: 9%;
  height: 9vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}
.button__next--step:hover{
  border-radius: 50%;
  background-color: rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.button__next--step p{
  width: auto;
  color: white;
  padding: 0;
  margin: 0;
}
.container_products--container__align {
  display: block;
  justify-content: center;
  align-items: center;
  overflow-y:scroll;
  height: 80vh;
}
.container_products--container__align::-webkit-scrollbar {
  display: none;
}
.container_products--container {
  height: auto;
  width: 880px;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
//   display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

   @media (min-width: 576px) and (max-width: 767.98px) { 
    width: auto;
  }

  @media (max-width: 575.98px) { 
    width: auto;
  }
  
}
.container_products--container__boton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
}
.container_products--container__boton p {
  width: 100%;
  text-align: center;
  margin-top: 5px;
}
.container_products--container__title {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 3px 6px #00000029;
}

.container_products--geographical {
  height: 170px;
  width: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
  margin-left: -40px;
  color: #FFFFFF;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-size: 20px;
  text-transform: uppercase;
   @media(max-width: 578px){
       display: none;
    }
}

.container_products--container__title p {
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  padding: 0 20px;
}
.container_products--container__metric {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  padding: 30px 30px 0px 30px;
}
.one__product {
  height: 450px;
}
.container_metric{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #F4F4F4;
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 20px;
  padding: 20px;
}

.container_metric--description{
  text-align: left;
  font: normal normal normal 13px/21px Roboto;
  letter-spacing: 0.33px;
  color: #383838;
  margin-bottom: 0px;
}

.container_metric--title{
  text-align: left;
  font: normal normal normal 13px/21px Roboto;
  letter-spacing: 0.33px;
  color: #383838;
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
}

</style>