import { render, staticRenderFns } from "./GraphTableModalCategory.vue?vue&type=template&id=36b9a572&scoped=true&"
import script from "./GraphTableModalCategory.vue?vue&type=script&lang=js&"
export * from "./GraphTableModalCategory.vue?vue&type=script&lang=js&"
import style0 from "./GraphTableModalCategory.vue?vue&type=style&index=0&id=36b9a572&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b9a572",
  null
  
)

export default component.exports