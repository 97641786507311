<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="43.048" viewBox="0 0 60 43.048">
        <g transform="translate(0 -58.303)">
            <g transform="translate(0 58.303)">
                <path 
                    :fill="color"
                    class="a" 
                    d="M58.837,61.886H54.883V60.536a1.163,1.163,0,0,0-.837-1.163A32.76,32.76,0,0,0,45.66,58.3c-4.652,0-10.991,1.047-15.677,5.815C25.33,59.3,18.946,58.3,14.305,58.3a32.76,32.76,0,0,0-8.385,1.07,1.163,1.163,0,0,0-.837,1.163v1.337H1.163A1.163,1.163,0,0,0,0,63.037v37.147A1.16,1.16,0,0,0,1.733,101.2c.116-.058,11.746-6.42,27.982-2.326h.279a1.164,1.164,0,0,0,.279,0c16.282-4.129,27.912,2.221,27.982,2.326a1.163,1.163,0,0,0,1.745-1V63.049A1.163,1.163,0,0,0,58.837,61.886ZM2.326,98.334V64.212h2.78V93.38a1.163,1.163,0,0,0,1.337,1.163,50.9,50.9,0,0,1,7.269-.582,30.168,30.168,0,0,1,9.932,1.5A42.755,42.755,0,0,0,2.326,98.334Zm11.386-6.687a51.171,51.171,0,0,0-6.327.407v-30.6a32.077,32.077,0,0,1,6.9-.768c4.35,0,10.351,1,14.514,5.7l.035,28.971C26.412,93.775,21.69,91.647,13.712,91.647ZM31.157,66.386c4.164-4.652,10.165-5.7,14.514-5.7a32.218,32.218,0,0,1,6.9.768v30.6a51.16,51.16,0,0,0-6.292-.419c-7.932,0-12.665,2.128-15.119,3.71ZM57.662,98.334A42.764,42.764,0,0,0,36.3,95.462a30.24,30.24,0,0,1,9.979-1.5,50.88,50.88,0,0,1,7.315.57,1.163,1.163,0,0,0,1.337-1.163V64.212h2.78Z" transform="translate(0 -58.303)" 
                />
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    props:{
        color: {
            default: '#3E73BE',
            type: String
        }
    }
}
</script>