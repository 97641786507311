<template>
    <div class="dimensions_container" style="border: none;">
        <b-overlay :show="loadDimension">
            <div
                v-for="(Dimension, keyDimension) in dimensions" 
                :key="keyDimension"
            >
               <div class="dimension_container_row" v-if="dimensions.length > 0">
                   <div class="dimensions_container__dimension" @click="toggleDimension(Dimension)">
                       <div 
                            class="dimensions_container__dimension--img" 
                            :style="{'background-image': `url(${Dimension.img_dimension.url})`, 'background-size': 'cover', 'background-position': 'center'}"
                        >
                        </div>
                        <div class="dimensions_container__dimension--text" :style="{'background-color': Dimension.color_dimension}">
                            <p>
                                {{Dimension.name_dimension}} 
                            </p>
                            <img src="@/assets/img/arrow-up.svg" class="dimensions_container__dimension--arrow" v-if="!Dimension.open">
                            <img src="@/assets/img/arrow-up.svg" v-else style="transform: rotate(180deg)">
                        </div>
                   </div>
               </div>
                
                <div 
                    v-if="Dimension.open" 
                    class="container_movile_segments"
                    style="transition: all 0.5s ease-in-out;"
                >
                    <div class="movile_segments__list">
                        <a 
                          class="movile_segments__list--item" 
                          v-for="(Segment, keySegment) in Dimension.segments" 
                          :key="keySegment"
                          @click="getRoute('Segments', Dimension.id)"
                        >
                          {{Segment.name_architecture}} 
                          <ArrowUpIcon width="18.828" height="10.414" color="#B1B1B1" style="transform: rotate(90deg)" />
                        </a>
                    </div>
                </div>
            </div>
            
         </b-overlay>
    </div>
</template>
<script>
export default {

  props:['subcategory'],

  data() {
    return {
      observatorio:this.getClient(),
      dimensions:[],
      accordions:[],
      categories:[],
      other:[],
      segmento:[],
      expanded: false,
      loadDimension: true
    }
  },
  methods: {
    getRoute(name, param){
      if (this.$route.params.id !== param) {
          this.$router.push({name: name, params: {id: param}});
          if (name === this.$route.name) {
            this.$router.go()
          }
      }
    },
    toggleDimension(item){
        item.open = !item.open
    },
    async fullCategories(){
      //console.log('ingresando a full')
      try{
          const fullCat = await this.$http.get('architecture_tags/dimension/'+this.id)
          this.categories=fullCat.data
      }catch(error){
          //console.log(error)
      }
    },
    getDimension(id){
      if(id == 55){
        window.location.href ="https://fuseaccess.com/"
      }else{
        this.$router.push({name: 'Dimension', params: {id: id}});
      }
    },
    getCategory(Dimension, Category){
      this.$router.push({name: 'Dimension', params: {id: Dimension}, query: {Category: Category}});
    },
    getDimensionInfo(){
      this.dimensions = [];
      this.$http.get('dimensions/townhall/'+this.getClient().id).then(response => {
        //this.dimensions=response.data;

        //this.dimensions = ({...response.data, open: false})


        this.dimensions = response.body.map(item => ({
          //architecture: item.architecture,
          color_dimension: item.color_dimension,
          id: item.id,
          img_dimension: item.img_dimension,
          name_dimension: item.name_dimension,
          segments: item.segments,
          //text_dimension: item.text_dimension,
          townhall_id: item.townhall_id,
          subcategory: item.subcategory,
          open: false
        }));

        if( this.subcategory !== 1){
          this.dimensions = this.dimensions.filter(item => item.subcategory !== 1);
        }else{
          this.dimensions = this.dimensions.filter(item => item.subcategory == 1);
        }

        this.loadDimension = false;

      }).catch(error => {
        //console.log('ERROR AL CONSULTAR LAS DIMENSIONES', error)
      })
    },
  },
  created() {
    this.getDimensionInfo();
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      
      this.accordions.forEach((element, index) => {
        if (element.name == collapseId) {
          this.accordions[index].toggle = isJustShown
        }
      });
    });
  },
}
</script>
<style lang="scss" scoped>
    .dimensions_container{
        padding-top:5px;
        padding-bottom:0px;
        padding-left: 10px;
        padding-right: 10px;
        .dimension_container_row{
            transition: width 600ms ease-out, height 600ms ease-out;
        }
        .dimensions_container__dimension{
            height: 60px;
            margin-bottom: 8px;
            transition: width 600ms ease-out, height 600ms ease-out;
            
            .dimensions_container__dimension--text p{
                font-size: 15px;
                font-weight: 400;
                letter-spacing: 0.4px;
                transition: width 900ms ease-out, height 600ms ease-out;
            }

            .dimensions_container__dimension--img{
                width: 70px;
                transition: width 900ms ease-out, height 600ms ease-out;
            }
        }
        .container_movile_segments{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin-top: -8px;
            transition: opacity .4s;

            .movile_segments__list{
                background: #F0F0F0 0% 0% no-repeat padding-box;
                border: 1px solid #E8E8E8;
                border-radius: 0px 0px 5px 5px;
                padding: 0px 10px;
                min-width: 82.5%;
                width: 82.5%;
                max-width: 85%;
                margin-bottom: 8px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;

                .movile_segments__list--item{
                    text-align: left;
                    font: normal normal normal 14px/19px Roboto;
                    letter-spacing: 0.35px;
                    color: #707070;
                    border-bottom: 1px solid #C6C6C6;
                    width: 100%;
                    padding: 15px 0px;

                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    cursor: pointer;
                    align-items: center;
                }

                .movile_segments__list--item:last-child {
                    border-bottom: none;
                }
            }
        }
        
        
    }
</style>