<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 18.828 10.414">
    
    <path 
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2px"
        class="a" d="M499.51,196l8,8-8,8" 
        transform="translate(-194.586 508.51) rotate(-90)" 
    />
    </svg>
</template>

<script>
export default {
    props:{
        color: {
            default: '#808191',
            type: String
        },
        width: {
            default: '14',
            type: String
        },
        height: {
            default: '8',
            type: String
        }
    }
}
</script>