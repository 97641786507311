<template>
    <div>


        <div class="header_about_dimension" >
          <h1 class="header_about_dimension__title">
            Tu Alcaldía
          </h1>
        </div>

        <a  @click="goTo()">
            <div class="container_about_dimension" v-if="lang == 'es'">
                <button class="about_dimension_button" @click="goTo()">
                Más información
                </button>
            </div>

            <div class="container_about_dimension_en" v-if="lang == 'en'">
                <button class="about_dimension_button" @click="goTo()">
                Más información
                </button>
            </div>
        </a>
    </div>
</template>
<script>
export default {
    name: 'AboutDimension',

    data(){
        return {
          lang:''  
        }
    },

    created(){
        this.lang = localStorage.getItem('lang')
    },

    methods:{
      goTo(){
        this.$router.push({name:'SubCategoryProduct'})
      }
    }
};
</script>

<style lang="scss" scoped>
</style>