<template >
    <div class="container__modal--map">
        <div class="container__modal--map__graph">
            <div class="container__modal--map__close">
                <h3>{{type.name}}</h3>
                <div></div>
                <div class="container__modal--map__close--img">
                    <img :src="require('../../assets/img/closex.png')" @click="getOutModal()">
                </div>
            </div>
            <div class="container__modal--map__graph--bars" v-if="model=='grafica'">
                <div class="container__bars">
                    <div v-if="type.type == 1">
                        <line-chart
                            height="60vh"
                            :data="chartData"
                            :messages="{ empty: 'No data' }"
                        ></line-chart>
                    </div>
                    <div v-if="type.type == 2">
                        <column-chart
                            :data="chartData"
                            :messages="{ empty: 'No data' }"
                            height="60vh"
                        ></column-chart>
                    </div>
                    <div v-if="type.type == 3">
                        <pie-chart height="60vh" :data="pieData" :messages="{ empty: 'No data' }"></pie-chart>
                    </div>
                    <div v-if="type.type == 4">
                        <bar-chart 
                        height="60vh"
                        :data="chartData" 
                        :messages="{ empty: 'No data' }"
                        ></bar-chart>
                    </div>
                    <div v-if="type.type == 5">
                        <area-chart
                            :data="chartData"
                            :messages="{ empty: 'No data' }"
                            height="60vh"
                        ></area-chart>
                    </div>
                    <div v-if="type.type == 9">
                        <scatter-chart
                            :data="chartData"
                            :messages="{ empty: 'No data' }"
                            height="60vh"
                        ></scatter-chart>
                    </div>
                    <div v-if="type.type == 6">
                        <bar-chart 
                        height="60vh"
                        :data="barsData" 
                        :messages="{ empty: 'No data' }"
                        ></bar-chart>
                    </div>
                    <div v-if="type.type == 7">
                        <area-chart
                            height="60vh"
                            :data="barsData"
                            :messages="{ empty: 'No data' }"
                        ></area-chart>
                    </div>
                    <div v-if="type.type == 8">
                        <scatter-chart
                            height="60vh"
                            :data="barsData"
                            :messages="{ empty: 'No data' }"
                        ></scatter-chart>
                    </div>
                </div>

        </div>
        <div class="container__maps" v-if="model=='mapa'">
            <h2 class="title__map">{{type.name}}</h2>
            <div>
                <div class="container_map" :id="type.name+'modal'" ref="tableau"></div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        type:{
            type:Object
        }
    },
    data(){
        return{ 
        model:'',
        chartData: {},
        pieData: {},
        barsData: {},
        options: {
            hideTabs: true,
            width: "50vw",
            height: "65vh",
        },            
        }
    },
    methods:{
        getOutModal(){
            this.$emit('cancelModal',false)
            // this.showModal=false   
            console.log('Respuesta ::: ',this.showModal)  
        },
        initViz() {
            let elementMap = document.getElementById(this.type.name+'modal')
            let viz = new tableau.Viz(elementMap, this.type.url, this.options);
        },
        convertJson(){
            const x = JSON.parse(this.type.data)
            const info = []
            const twoInfo = []
            for( var i = 0 ; i < (x.length-1) ; i++){
            info.push(x[i+1])
            }
            const y = x
            for( var i = 0 ; i < (x.length-1) ; i++){
            var r = [y[i+1][0],y[i+1][2]]
            twoInfo.push(r)
            }
            this.chartData=[{name:x[0][1],data:info}]
            this.pieData=info
            this.barsData=[{name:x[0][1],data:info},{name:x[0][2],data:twoInfo}]            
        },
    },
    mounted() {
        if (this.type.url!= undefined){
            this.initViz() 
        }else{
            this.renderChart(data, options)
        }
        
    },
    created(){   
        console.log(this.type)
        if (this.type.url!= undefined){
            this.model='mapa'
        }else{
            this.model='grafica'
            this.convertJson()
        }   
    }
}
</script>
