<template>
    <div>
        <b-overlay :show="loadDimension" rounded="sm">
            <div class="container_site">
                <Menu-Movil />
                <Social/>
                <!--<RelevantNews /> -->
                <!-- <Banner/> -->
                <!-- <InfoSite /> -->
                <Info-Banner />
                <Info-Video />
                <About-Indicator />
                <About-Dimension />
                <About-Question />
                <!-- <Banner-Dynamic/> -->
                <!-- <Dimensions color_title="#FFFFFF" /> -->
            </div>
        </b-overlay>
    </div>
</template>
<script>
import viewDimensions from '@/components/views/Dimensions'
import viewInfoSite from '@/components/views/infoSite'
export default {
    data(){
        return{
            loadDimension: false,
         
        }
    },
    components:{
        Dimensions: viewDimensions,
        InfoSite: viewInfoSite,
    },

    created(){
        // console.log('this.$router.options.routes', this.$router.options.routes)
        
    },

    methods:{



    }
    
  
}
</script>

<style lang="scss" scoped>
    body{
        /*background-color: #043B62 !important;*/
        background-color: var(--blue) !important;
    }
</style>