<template>

<div>
        <div class="relevant_news">
        <!-- <template
            v-for="(item, index) in news"
        >
        <div 
            :key="index"
            v-if="index <= 2"
            class="relevant_new"
            @click="$router.push({ name: 'Article', params:{title: item.title.split(' ').join('_'), id: item.id} })"
        >
            <img :src="item.thumbnail" class="relevant_new__thumbnail">
            <div class="relevant_new__body">
                <div class="relevant_new__body--title" :title="item.title"> {{ item.title }}</div>
                <div 
                    class="relevant_new__body--description" 
                    v-html="item.description"
                ></div>
            </div>
        </div>
        <span class="relevant_news__divider" :key="item.id"></span>
        </template> -->

        <template>
            <div 
                class="relevant_new"
                @click="showModal(1)"
            >
                <img src="@/assets/img/imagen_dimension1.jpeg" class="relevant_new__thumbnail">
                <div class="relevant_new__body">
                    <div class="relevant_new__body--titleA1" >
                        Desarrollo económico 
                    </div>
                    <div class="relevant_new__body--titleB1" style="">
                        Necesidades humanas básicas
                    </div>
                    
                    <div class="relevant_new__body--description1" style="">
                     Resultados de las encuestas
                    </div>
                    

                </div>
                    <!-- <div class="relevant_new__body--icon">
                        <img src="@/assets/img/icono_mano.png" style="width: 100%;">
                    </div> -->
            </div>
            
        </template>

        <template>
            <div 
                class="relevant_new"
                @click="showModal(2)"
                style="margin-right: 20px;margin-left: 20px;"
            >
                <img src="@/assets/img/imagen_dimension2.jpeg" class="relevant_new__thumbnail">
                <div class="relevant_new__body">
                    <!-- <div class="relevant_new__body--title" >Fundamentos de bienestar</div> -->
                    <div class="relevant_new__body--titleA2" style="text-align:center;">
                        Fundamentos
                    </div>
                    <div class="relevant_new__body--titleB2" style="text-align:center;">
                        de bienestar
                    </div>

                    <div class="relevant_new__body--description2" style="">
                      Resultados de las encuestas
                    </div>
                </div>
                   <!-- <div class="relevant_new__body--icon">
                        <img src="@/assets/img/icono_mano.png" style="width: 100%;">
                    </div> -->
            </div>
            
            
        </template>


        <template>
            <div 
                class="relevant_new"
                @click="showModal(3)"
            >
                <img src="@/assets/img/imagen_dimension3.jpeg" class="relevant_new__thumbnail">
                <div class="relevant_new__body">
                    <div class="relevant_new__body--titleA3" style="">Oportunidades</div>
                    <div class="relevant_new__body--description3" style="">
                      Resultados de las encuestas
                    </div>
                </div>
                   <!-- <div class="relevant_new__body--icon">
                        <img src="@/assets/img/icono_mano.png" style="width: 100%;">
                    </div> -->
            </div>
            
        </template>




        
        </div>

    

        <vue-glide 
            perView="1"
            startAt="0" 
            autoplay="true"
            rewindDuration="2000"
            animationDuration="2000"
            dragThreshold="true"
            swipeThreshold="true"
        >
            <vue-glide-slide>
               <div class="relevant_news_movil">

                <template>
                    <div 
                        class="relevant_new_movil"
                        @click="showModal(1)"
                    >
                        <img src="@/assets/img/imagen_dimension1.jpeg" class="relevant_new_movil__thumbnail">
                        <div class="relevant_new_movil__body">
                            <div class="relevant_new_movil__body--titleA1" >
                                Desarrollo económico 
                            </div>
                            <div class="relevant_new_movil__body--titleB1" style="">
                                Necesidades humanas básicas
                            </div>
                            
                            <div class="relevant_new_movil__body--description1" style="">
                            Resultados de las encuestas
                            </div>
                            

                        </div>
                            <!-- <div class="relevant_new__body--icon">
                                <img src="@/assets/img/icono_mano.png" style="width: 100%;">
                            </div> -->
                    </div>
                    
                </template>

                </div>
            </vue-glide-slide>

            <vue-glide-slide>
               <div class="relevant_news_movil">

                    <template>
                        <div 
                            class="relevant_new_movil"
                            @click="showModal(2)"
                            style="margin-right: 20px;margin-left: 20px;"
                        >
                            <img src="@/assets/img/imagen_dimension2.jpeg" class="relevant_new_movil__thumbnail">
                            <div class="relevant_new_movil__body">
                                <!-- <div class="relevant_new__body--title" >Fundamentos de bienestar</div> -->
                                <div class="relevant_new_movil__body--titleA2" style="text-align:center;">
                                    Fundamentos
                                </div>
                                <div class="relevant_new_movil__body--titleB2" style="text-align:center;">
                                    de bienestar
                                </div>

                                <div class="relevant_new_movil__body--description2" style="">
                                Resultados de las encuestas
                                </div>
                            </div>
                            <!-- <div class="relevant_new__body--icon">
                                    <img src="@/assets/img/icono_mano.png" style="width: 100%;">
                                </div> -->
                        </div>
                        
                        
                    </template>

                </div>
            </vue-glide-slide>

              <vue-glide-slide>
               <div class="relevant_news_movil">

                    <template>
                        <div 
                            class="relevant_new_movil"
                            @click="showModal(3)"
                        >
                            <img src="@/assets/img/imagen_dimension3.jpeg" class="relevant_new_movil__thumbnail">
                            <div class="relevant_new_movil__body">
                                <div class="relevant_new_movil__body--titleA3" style="">Oportunidades</div>
                                <div class="relevant_new_movil__body--description3" style="">
                                Resultados de las encuestas
                                </div>
                            </div>
                            <!-- <div class="relevant_new__body--icon">
                                    <img src="@/assets/img/icono_mano.png" style="width: 100%;">
                                </div> -->
                        </div>
                        
                    </template>

                </div>
            </vue-glide-slide>

        </vue-glide>




    </div>    

</template>
<script>
import pdf from 'vue-pdf'
// var loadingTask = pdf.createLoadingTask('pdf/infografias_dimension1_Desarrollo_economico.pdf');
export default {

     components: {
        pdf
    },

    data(){
        return {
            news: [],
            show: false,
            loading: false,
            pdfSelect:'',
            pdf: '',
            currentPage: 0,
            pageCount: 0,
            numPages: undefined,
            imgSelect:''
        }
    },

    mounted() {

		// this.pdf.promise.then(pdf => {

		// 	this.numPages = pdf.numPages;
		// });
	},

    methods:{

        showModal(data){

            this.loading = true
            this.pdf = ''

            let me = this

            this.pdfSelect = data
            this.imgSelect = data
            var loadingTask
            // console.log('data', data)
            // if(data == 1){
            //     loadingTask = pdf.createLoadingTask('pdf/infografias_dimension1_Desarrollo_economico.pdf');
            //     // this.pdf = 'pdf/infografias_dimension1_Desarrollo_economico.pdf'
            //     this.pdf = loadingTask
            //     // this.img = 'infografias_dimension1_Desarrollo_economico.jpg'
            // }else if(data == 2){
            //     loadingTask = pdf.createLoadingTask('pdf/infografias_dimension2_Fundamentos_de_bienestar.pdf');
            //     this.pdf = loadingTask
            // }else if(data == 3){
            //     loadingTask = pdf.createLoadingTask('pdf/infografias_dimension3_Oporunidades.pdf');
            //     this.pdf = loadingTask
            // }

            // this.pdf.promise.then(pdf => {

            //    me.numPages = pdf.numPages;
            //    me.loading = false
            // });

            this.show = true
        },

        getArticles(){
            this.$http.get("articles/townhall/"+this.getClient().id)
            .then((response)=>{
                this.news = response.data
                console.log('this news', this.news)
            })
        }
    },
    beforeMount(){
        this.getArticles()
    }
}
</script>