<template>
    <div  class="global_background_img global_background_color">
        <div class="container_site">
            <Nav-bar/>
        </div>
        <router-view></router-view>
        <Footer />
    </div>
</template>
<script>
export default {
    data(){
        return{
            loadDimension: false
        }
    },
    created(){
        if (this.$router.currentRoute.name === 'Inicio' ) {
            this.$router.push({name: 'Home'});
        }
    },
    computed: {
    //     styleObject: function() {
    //         if(this.$route.name === 'Home'){
    //             return {
    //                 'background-color': '#043B62',
    //             }
    //         }else{
    //             return {
    //                 'background-color': '#F3F4F6',
    //             }
    //         }
            
    //     }
    // }
}
}
</script>

<style scoped>
.global_background_img{
    background-image: url('../../assets/img/Fondo-estatico.png') !important;
    
}

.global_background_color{
    background-color: #022238 !important;
}
</style>