<template>
    <div>
        
               <div class="container_nav__movil--menu">

                <div 
                    class="container_nav__movil--item_menu" 
                    :class="$route.name == 'AboutObservatorio' ? 'active_item_desktop':''" 
                     @click="getRoute('AboutObservatorio')"
                    style="background: #1796BE; text-indent: 8px; padding: 0px 0px 0px 11px !important;line-height: normal;"
                >
                    ¿Qué es el Observatorio?
                </div>

                <div 
                    class="container_nav__movil--item_menu" 
                    
                    
                    
                    style="background: #0B6C37"
                >
                    Mapa del sitio
                </div>

                <div 
                    class="container_nav__movil--item_menu" 
                    
                    :class="$route.name == 'Contact' ? 'active_item_desktop':''" 
                    @click="getRoute('Contact')"
                    style="background: #043C61"
                >
                    Contáctanos
                </div>
 
            </div>
    </div>        
</template>
<script>
export default {

    name: '',

    data(){
        return {
            
        }
    },
    methods:{
       getRoute(name){
            if (this.$route.name != name) {
                this.$router.push({name: name});
            }
        }
    },


};
</script>

<style lang="scss" scoped>
</style>