<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="17" viewBox="0 0 23 17">
    <g transform="translate(-3 -9.5)">
        <path 
            :fill="color" 
            stroke="#000" 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            stroke-width="3px" 
            class="a" 
            d="M4.5,18h20" 
        />
        <path
            :fill="color" 
            stroke="#000" 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            stroke-width="3px" 
            class="a" d="M4.5,9h20" 
            transform="translate(0 2)" 
        />
        <path 
            :fill="color" 
            stroke="#000" 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            stroke-width="3px" 
            class="a" 
            d="M4.5,27h20" 
            transform="translate(0 -2)" 
        />
    </g>
</svg>
</template>
<script>
    export default {
        props:{
            color: {
                default: '#000',
                type: String
            }
        }
    }
</script>