<template>
    <div id="Container-Banner" :style="{'background-image': `url(${observatorio.landing_img.url})`, 'background-size': 'cover', 'background-position': 'center'}">
        <!-- <div class="Container-Text_Banner">
            <div class="Banner-Text_Observatorio">OBSERVATORIO</div>
            <div class="Banner-Text_TerritorialRetiro">{{observatorio.name_townhall}}</div>
        </div> -->
    </div>
</template>
<script>
export default {
   
    data() {
        return {
            observatorio:this.getClient()
        }
    },
}
</script>