<template>
    <div>
        <div id="ContentMobile">
            <router-view/>
        </div>
        <div id="Content-NoMobile">
            <Container-Inicio/>

            <div class="Titulo_Dimensiones">
                <div class="Titulo_Dimensiones_Dimensiones" v-b-tooltip.hover.right title="Dimensiones del Plan de Desarrollo">Dimensiones</div>
                <div class="Titulo_Dimensiones_Arquitectura">Arquitectura de información</div>
            </div>
            <div class="Container-InfoDimensiones">
                <Container-Dimensiones />
                <Container-Observatorio />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            BoxSupport: false,
            obs:this.$route.params.observatorio,
            observatorio:[]
        }
    },
    
}
</script>
