const state = {
    graph: []
}
  
const getters = {
    getGraph(state){
        return state.graph;
    }
}
  
const mutations = {
    setGraph(state, payload){
        state.graph = payload;
    },
    setDeleteGraph(state){
        state.graph.splice(0,state.graph.length)
    }
}
  
const actions = {
    updateGraph ({commit}, payload) {
        commit('setGraph', payload)
    },
    
}
  
  export default {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
  }